import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import {map, retry} from 'rxjs/operators'
import { GlobalConstants } from '../common/global-constants';
import { FunctionServiceService } from './function-service.service';
import { Router } from '@angular/router';
import { ToastController, Platform, ModalController, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { fn } from '@angular/compiler/src/output/output_ast';
import { ProfileMgtPage } from '../pages/profiles/profile-mgt/profile-mgt.page';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private alertCtrl:AlertController
  public profileData1:Observable<any>;
  public profileData:BehaviorSubject<any>;
  public profileDataAdmin:BehaviorSubject<any>;
  private isLogged: BehaviorSubject<boolean>;
  private isLoggedAdmin: BehaviorSubject<boolean>;
  private resource:BehaviorSubject<any>;
  private reload:BehaviorSubject<any>;
  private date:BehaviorSubject<any>;
  private search:BehaviorSubject<any>;
  private states:BehaviorSubject<any>;
  private lga:BehaviorSubject<any>;
  private systemType:BehaviorSubject<any>;
  private candidate:BehaviorSubject<any>;
  private countries:BehaviorSubject<any>;
  private inst:BehaviorSubject<any>;
  private depts:BehaviorSubject<any>;
  private faculties:BehaviorSubject<any>;
  private roles:BehaviorSubject<any>;
  private how:BehaviorSubject<any>;
  private courses:BehaviorSubject<any>;
  api = GlobalConstants.baseUrl;
  //api = 'http://localhost/presspay/ap/Mock/';
  //api2 = 'http://localhost/presspay/ap/Mock/';
  //api2 = 'https://presspay.ng/ap/Mock/';
  constructor(
    private http:HttpClient,
    private storage:Storage,private router:Router,
    private toastCtrl:ToastController,
    private platform:Platform
  ) {
    this.createStorage();
    if(window.location.href.split('localhost').length<=1){
      //this.api2 = 'https://presspay.ng/ap/Mock/';
      this.api = 'https://presspay.ng/studentloan/';
    }
    this.search = new BehaviorSubject<string>('');
    this.date = new BehaviorSubject<string>('Today');
    this.isLogged = new BehaviorSubject<boolean>(false);
    this.isLoggedAdmin = new BehaviorSubject<boolean>(false);
    this.profileData = new BehaviorSubject<any[]>([]);
    this.profileDataAdmin = new BehaviorSubject<any[]>([]);
    this.resource = new BehaviorSubject<any[]>([]);
    this.reload = new BehaviorSubject<any>({});
    this.states = new BehaviorSubject<any>([]);
    this.lga = new BehaviorSubject<any>([]);
    this.countries = new BehaviorSubject<any>([]);
    this.systemType = new BehaviorSubject<any>(true);
    this.candidate = new BehaviorSubject<any>([]);
    this.inst = new BehaviorSubject<any>([]);
    this.depts = new BehaviorSubject<any>([]);
    this.faculties = new BehaviorSubject<any>([]);
    this.roles = new BehaviorSubject<any>([]);
    this.how = new BehaviorSubject<any>([]);
    this.courses = new BehaviorSubject<any>([]);
  }
  async createStorage(){
    await this.storage.create();
  }
  cannotVerify = false;
  checkUserVerification(){
    if(this.getUrlPath3().includes('slider')){
      return;
    }
    var isChecked = 0;
    setTimeout(()=>{
      this.getProfileValue().subscribe((data:any)=>{
        if(isChecked>0)
          return;
        isChecked++;
        if(data && data?.user && !data?.user?.email_verified_at && data?.user?.role){
          if(Object.keys(data).length>0){
            //your email has not been verified
            if(this.getUrlPath3().includes('verify'))
            return;
            if(!this.cannotVerify)
              this.loadPath('verify-account');
            else{
              this.checkUserProfiling();
            }
          }
          else{
            this.loadReplace('sign-in');
          }
        }
        else{
          if(data.user && data.user.email_verified_at){
            this.checkUserProfiling();
            if(this.getUrlPath3().includes('overview') || this.getUrlPath3().includes('-information'))
              return;
            if(this.getUrlPath3().includes('verify'))
              this.loadReplace('overview');
          }
          //this.presentToast('Email has been verified');
        }
      })
    },3000)
  }
  checkUserProfiling(){
    if(this.getUrlPath3().includes('-information') || this.getUrlPath3().includes('sign-in'))
      return;
    this.getProfileValue().subscribe((data:any)=>{
      if(data && data.user && data.user.role=='1' && (data && data.candidate &&  (!data.candidate.institution || !data.candidate.first_name) || !data.candidate?.id)){
        this.loadPath('profile-information');
      }
      else if(data && data.user && data.user.role=='2' && (data && !data.lender)){
        this.loadPath('lender-information');
      }
      else if(data && data.user && data.user.role_name?.includes('parent') && (data && !data.parent)){
        this.loadPath('parent-information');
      }
      else if(data && data.user && (data.user.role=='3' || data.user.role=='4') && (data && (!data.inst && !data.admin ))){
        this.loadPath('institution-information');
      }
      else{
        if(this.getUrlPath3().includes('overview'))
          return;
        if(this.getUrlPath3().includes('profile-information'))
          this.loadReplace('overview');
      }
    })
  }
  //oneSignal = new OneSignal();
  public getUrlPath3(){
    return this.router.url;
  }
  public getUrlPath(){
    return window.location.href;
  }
  async verifyLogin(){
    if(this.getUrlPath3().includes('slider') || this.getUrlPath3().includes('verify')){
      if(this.getUrlPath3().includes('verify')){
        setTimeout(()=>{
          if(!GlobalConstants.token){
            if(!this.getUrlPath3().includes('sign'))
              this.loadReplace('/sign-in');
          }
          else{
            //check if user has been verifed
            this.getProfileValue().subscribe((data:any)=>{
              if(data && data.user){
                if(data.user.email_verified_at){
                  this.loadPath('welcome')
                }
              }
            })
          }
        },2000)
      }
    }
    else{
      const value = await this.storage.get(GlobalConstants.tokenName);
      var urlPath = this.getUrlPath3();
      if(!value){
        if(urlPath.includes('sign-up') || urlPath.includes('reset'))
          return;
        if(!this.getUrlPath3().includes('sign'))
          localStorage.setItem('redir',this.getUrlPath3());
        this.loadReplace('/sign-in');
      }
      else{
        //check if account is verified
        var startCheck = 0;
        if(urlPath.includes('sign-in')){
          this.loadReplace('welcome')
        }
        else{
          this.getProfileValue().subscribe((data:any)=>{
            if(data?.user && !data?.user?.email_verified_at && data?.user?.role){
              if(startCheck>0){
                return;
              }
              startCheck++;
              this.loadReplace('verify-account')
            }
          })
        }
      }
    }
  }
  public loadPath(path){
    this.router.navigate([path]);
  }
  public loadReplace(path){
    this.router.navigate([path],{replaceUrl:true});
  }
  checkLogin(){
    return new Promise((resolve) => {
      this.storage.get(GlobalConstants.tokenName).then((data)=>{
        if(data){
          GlobalConstants.token = data;
          return true;
        }
        else{
          GlobalConstants.isLogged = false;
          return false;
        }
      })
    })
  }
  getReload(): Observable<any> {
    return this.reload.asObservable();
  }
  setReloadEnd(){
    // Emit something to stop all Observables
    this.reload.next({});
    // Complete the notifying Observable to remove it
    this.reload.complete();
  }
  setReload(newValue,obj?): void {
    if(obj)
      this.reload.next({'name':newValue,'object':obj});
    else{
      if(!newValue || newValue==''){
        this.setReloadEnd();
      }
      else
      this.reload.next(newValue);
    }
  }
  getSearch(): Observable<boolean> {
    return this.search.asObservable();
  }
  setSearch(newValue): void {
    this.search.next(newValue);
  }
  getState(): Observable<boolean> {
    return this.states.asObservable();
  }
  setState(newValue): void {
    this.states.next(newValue);
  }
  getLGA(): Observable<boolean> {
    return this.lga.asObservable();
  }
  setLGA(newValue): void {
    this.lga.next(newValue);
  }
  getCountry(): Observable<boolean> {
    return this.countries.asObservable();
  }
  setCountry(newValue): void {
    this.countries.next(newValue);
  }
  getInst(): Observable<boolean> {
    return this.inst.asObservable();
  }
  setInst(newValue): void {
    this.inst.next(newValue);
  }
  getDept(): Observable<boolean> {
    return this.depts.asObservable();
  }
  setDept(newValue): void {
    this.depts.next(newValue);
  }
  setFaculty(newValue): void {
    this.faculties.next(newValue);
  }
  getFaculty(): Observable<boolean> {
    return this.faculties.asObservable();
  }
  setCourse(newValue): void {
    this.courses.next(newValue);
  }
  getCourse(): Observable<boolean> {
    return this.courses.asObservable();
  }
  setRoles(newValue): void {
    this.roles.next(newValue);
  }
  getRoles(): Observable<boolean> {
    return this.roles.asObservable();
  }
  setHow(newValue): void {
    this.how.next(newValue);
  }
  getHow(): Observable<boolean> {
    return this.how.asObservable();
  }
  getSystemType(): Observable<boolean> {
    return this.systemType.asObservable();
  }
  setCandidateValue(newValue): void {
    this.candidate.next(newValue);
  }
  getCandidateValue(): Observable<boolean> {
    return this.candidate.asObservable();
  }
  setSystemType(newValue): void {
    this.systemType.next(newValue);
  }
  getDate(): Observable<boolean> {
    return this.date.asObservable();
  }
  setDate(newValue): void {
    this.date.next(newValue);
  }
  getValue(type?): Observable<boolean> {
    this.storage.get(GlobalConstants.tokenName).then((data)=>{
      //console.log(data)
      if(!data){
        GlobalConstants.isLogged = false;
        return false;
      }
      else{
        GlobalConstants.isLogged = true;
        return true;
      }
    })
    return this.isLogged.asObservable();
  }
  setValue(newValue,type?): void {
    this.isLogged.next(newValue);
  }
  getProfileValue(type?): Observable<any> {
    if(type)
      return this.profileDataAdmin.asObservable();
    else
      return this.profileData.asObservable();
  }
  setProfile(newValue,type?): void {
    if(newValue?.candidate?.candidate_type){
      this.storage.set(GlobalConstants.tokenName+'_ctype',newValue?.candidate?.candidate_type).then((data)=>{
      })
    }
    this.storage.set(GlobalConstants.tokenName+'_profile',newValue).then((data)=>{
    })
    this.profileData.next(newValue);
  }
  fecthUserServer(id){
    this.getUser(id).subscribe((data:any)=>{
      //console.log(data)
      if(data && (data.Status=='Success' || (data && data.data && data.data.id))){
        var result;
        if(data.data && data.data.id){
          result = data.data;
        }
        else{
          result = data.Message;
        }
        this.setProfile(result);
        this.storage.set(GlobalConstants.tokenName+'_profile',result).then((done)=>{
        })
      }
      else{
        console.warn(data.Message)
      }
    },error=>{
      this.loadError(error)
    })
  }
  getProfileData(noLocal?){
    if(noLocal){
      this.fetchProfileServer();
    }
    else{
      this.storage.get(GlobalConstants.tokenName+'_profile').then((data)=>{
        if(data)
          this.setProfile(data)
      })
      this.fetchProfileServer();
    }
  }
  getResValue(): Observable<any> {
    return this.resource.asObservable();
  }
  setResValue(newValue): void {
    this.resource.next(newValue);
  }
  getResourceFn(type?){
    if(!type){
      this.storage.get('resource').then((data)=>{
        if(data){
          this.setResValue(data)
        }
      })
    }
    this.setResValue('');
    if(GlobalConstants.isLogged && GlobalConstants.token){
      this.getResource({}).subscribe((data)=>{
        GlobalConstants.resource = data;
        this.setResValue(data);
        this.storage.set('resource',data).then((data)=>{
        })
      },error=>{
        this.loadError(error)
      })
    }
    else{
      //get mini resource
      this.getResource({mini:true}).subscribe((data)=>{
        this.setResValue(data);
        this.storage.set('resource',data).then((data)=>{})
      },error=>{
        this.loadError(error)
      })
    }
  }
 setLocalProfile(){
   //console.log(this.getUrlPath3().includes('pos'))
    if(this.getUrlPath3().includes('pos')){
      return;
    }
    this.storage.get('profile_'+GlobalConstants.tokenName).then((data)=>{
      if(data){
        this.setProfile(data);
      }
    })
 }
 getProfile(data,type?):Observable<any>{
    if(GlobalConstants.isLogged && GlobalConstants.token){
      return this.http.post(`${this.api}users/fetch_profile`,data).pipe(
        map(results => results)
      ).pipe(retry(2));
    }
 }
 getResource(data):Observable<any>{
    return this.http.post(`${this.api}Resource`,data).pipe(
      map(results => results)
    ).pipe(retry(2));
 }
 update(data){
  return this.http.post(`${this.api}Admin/update`,data);
 }
 create(data){
  return this.http.post(`${this.api}major/create`,data);
 }
 createAccount(data){
  return this.http.post(`${this.api}Register`,data);
 }
 getUser(id,data?){
   var param;
   if(data) param = {params:data};
  return this.http.get(`${this.api}User/${id}`,param);
 }
 login(data){
   return this.http.post(`${this.api}Login`,data);
 }
 getCandidate(id,data?){
   if(!data)
    data = '';
  return this.http.get(`${this.api}Candidate/${id}${data}`);
 }
 getCandidateByParent(id,data?){
  if(!data)
   data = '';
 return this.http.get(`${this.api}CandidateByParent/${id}${data}`);
}
 getCandidates(data){
  if(!data)
   data = {};
 return this.http.get(`${this.api}Candidates`,{params:data});
}
 createCandidate(data){
  return this.http.post(`${this.api}Candidate`,data);
 }
 updateCandidate(data){
  return this.http.put(`${this.api}Candidate/`,data);
 }
 WalletHistory(id,type?){
   if(!type)
    type = '';
  return this.http.get(`${this.api}WalletHistory/${id}${type}`);
 }
 TargetSavings(id,type?){
  if(!type)
   type = '';
 return this.http.get(`${this.api}TargetSavings/${id}${type}`);
}
StopTargetSavings(data){
 return this.http.post(`${this.api}StopTargetSavings`,data);
}
deleteTargetSavings(id){
  return this.http.delete(`${this.api}TargetSavings/${id}`);
}
Wallet(id){
  return this.http.get(`${this.api}Wallet/${id}`);
 }




 import(data){
  return this.http.post(`${this.api}major/import`,data);
 }
 recoverAccount(data){
  return this.http.post(`${this.api}users/recoverAccount`,data);
 }
 downloadFile(route: string, filename: string = null): void{
    const headers = new HttpHeaders();
    headers.set('Animal', 'Bearer '+GlobalConstants.token);
    headers.set('Accept', 'application/pdf');
    this.http.get(this.api + route,{headers, responseType: 'blob' as 'json'}).subscribe(
        (response: any) =>{
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (filename)
                downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    )
  }
  downloadFilePost(route: string, filename: string = null,body?,type?): void{
    const headers = new HttpHeaders();
    headers.set('Animal', 'Bearer '+GlobalConstants.token);
    headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    //console.log(this.api+route)
    this.http.post(this.api + route,body,{headers, responseType: 'blob' as 'json'}).subscribe(
        (response: any) =>{
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            if(type)
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
            else
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (filename)
              downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    )
  }
  delete(data){
    return this.http.post(`${this.api}major/delete`,data);
  }
  signup(data){
    return this.http.post(`${this.api}users/create_account`,data);
  }
  getPlayerID(){
      if(!this.platform.is('capacitor')){
        if(!GlobalConstants.playerID && GlobalConstants.isTesting)
          GlobalConstants.playerID = '13ddd391-68e0-4abc-85a5-6f781a3c56ee'; 
      }
      else{
        /*
        this.oneSignal.getIds().then((ids)=>{
          GlobalConstants.playerID = ids.userId;
          this.getTags();
        });*/
        this.getPushStatus();
      }
  }
  getPushStatus(){
    /*
      if(!this.platform.is('capacitor')){
        if(GlobalConstants.isTesting)
          return true;
      }
      else{
        this.oneSignal.getPermissionSubscriptionState().then((status)=>{
          GlobalConstants.playerID = status.subscriptionStatus.userId;
          GlobalConstants.hasPush = status.subscriptionStatus.subscribed;
        },error=>{
          this.presentToast('An error occured while fetching push');
        }).catch((reason: any) => {
          this.presentToast(JSON.stringify(reason));
        });
      }
      */
  }
  subscribe(value:boolean){
    /*if(GlobalConstants.isCordova){
      this.oneSignal.registerForPushNotifications();
      this.oneSignal.setSubscription(value);
      if(value){
        GlobalConstants.hasPush = true;
      }
      else{
        GlobalConstants.hasPush = false;
      }
    }*/
  }
  async presentToast(txt,duration?,color?){
    const toast = await this.toastCtrl.create({
      message:txt,
      duration: duration?duration:4000,
      color: color?color:''
    });
    toast.present();
  }
  resendCode(data){
    return this.http.post(`${this.api}ResendCode`,data);
  }
  verify(data){
    return this.http.post(`${this.api}Verify`,data);
  }
  topUp(data){
    return this.http.post(`${this.api}TopUp`,data);
  }
  pretopUp(data){
    return this.http.post(`${this.api}VerifyTopUp`,data);
  }
  autoSave(data){
    return this.http.post(`${this.api}autoSave`,data);
  }
  requestVerification(data){
    //return this.http.post(`${this.api}RequestVerification`,data);
    return this.http.post(`${this.api}VerificationRequests`,data);
  }
  requestLoan(data){
    return this.http.post(`${this.api}requestLoan`,data);
  }
  updateLoanRequest(data){
    return this.http.put(`${this.api}requestLoan`,data);
  }
  refer(data){
    var api;
    if(window.location.href.includes('localhost'))
      api = 'http://localhost/presspay/api/major/refer';
    else
      api = GlobalConstants.siteUrl+'api/major/refer';
    return this.http.post(`${api}`,data);
  }
  withdraw(data){
    return this.http.post(`${this.api}Withdraw`,data);
  }
  withdrawSaving(data){
    return this.http.post(`${this.api}Wallet/Withdraw`,data);
  }
  reapply(data){
    return this.http.post(`${this.api}ReApply`,data);
  }
  optOut(data){
    return this.http.post(`${this.api}optOut`,data);
  }
  rePay(data){
    return this.http.post(`${this.api}rePay`,data);
  }
  loans(data){
    return this.http.post(`${this.api}loans`,data);
  }
  loanRepayments(id?){
    return this.http.get(`${this.api}LoanRepayments`);
  }
  GetLoanSIngle(id){
    return this.http.get(`${this.api}Loans/${id}`);
  }
  getLoans(id?,type?){
    ///${id}
    //return this.http.get(`${this.api}LoanRequest/${id}`);
    if(!type)
      type = '';
    return this.http.get(`${this.api}LoanRequest${type}`);
  }
  getLoansAdmin(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}LoanRequest/${count}/${page}`,{params:type});
  }
  getAgents(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Agents/${count}/${page}`,{params:type});
  }
  getStudents(type,count,page){
    if(!type)
    type = '';
    return this.http.get(`${this.api}Candidates/${count}/${page}`,{params:type});
  }
  getAcademicRecords(type,count,page){
    if(!type)
    type = '';
    return this.http.get(`${this.api}AcademicRecords/${count}/${page}`,{params:type});
  }
  getAcademicRecord(id?){
    return this.http.get(`${this.api}AcademicRecord/${id}`);
  }
  UpdateEduRecord(data){
    return this.http.post(`${this.api}UpdateAcademicRecord`,data);
  }
  AddEduRecord(data){
    return this.http.post(`${this.api}AcademicRecord`,data);
  }
  deleteEduRecord(data){
    return this.http.post(`${this.api}deleteAcademicRecord`,data);
  }
  deleteTuition(data){
    return this.http.post(`${this.api}deleteTuition`,data);
  }
  getTuitionPayments(id?){
    return this.http.get(`${this.api}TuitionPayments/${id}`);
  }
  PayTuition(data){
    return this.http.post(`${this.api}PayTuition`,data);
  }
  getTuitions(type,count,page){
    if(!type) type = '';
    return this.http.get(`${this.api}PayTuitions/${count}/${page}`,{params:type});
  }
  getTasks(type,count,page){
    if(!type) type = '';
    return this.http.get(`${this.api}Tasks/${count}/${page}`,{params:type});
  }
  uploadReceipt(data){
    return this.http.post(`${this.api}UploadReceipt`,data);
  }
  LenderLoans(id?){
    return this.http.get(`${this.api}LenderLoans`);
  }

  getCountries(data?){
    return this.http.post(`${this.api}Country`,data);
  }
  getStates(data?){
    return this.http.post(`${this.api}State`,data);
  }
  getLGAs(data?){
    return this.http.post(`${this.api}LGA`,data);
  }
  getInstitutions(data?){
    return this.http.post(`${this.api}Institution`,data);
  }
  getAllDepts(data?){
    return this.http.post(`${this.api}Department`,data);
  }
  getAllFaculties(data?){
    return this.http.post(`${this.api}Faculty`,data);
  }
  getDeptsByFaculty(id){
    return this.http.get(`${this.api}Departments/${id}`);
  }
  getFacultiesByUni(id){
    return this.http.get(`${this.api}Faculties/${id}`);
  }
  getCountryCall(force?){
    if(force){
      this.listCountries()
    }
    else{
      //check if data is already saved
      this.storage.get('countries').then((data)=>{
        if(!data){
          this.listCountries();
        }
        else{
          this.setCountry(data)
        }
      })

    }
  }
  listCountries(){
    this.getCountries({}).subscribe((data:any)=>{
      if(data.status){
        this.setCountry(data);
        this.storage.set('countries',data.data).then((data)=>{})
      }
    })
  }
  getStateCall(force?){
    if(force){
      this.listStates();
    }
    else{
      this.storage.get('states').then((data)=>{
        if(data){
          this.setState(data);
        }
        else{
          this.listStates();
        }
      })
    }
  }
  listStates(){
    this.getStates({}).subscribe((data:any)=>{
      if(data.status){
        this.setState(data.data)
        this.storage.set('states',data.data).then((data)=>{
        })
      }
    })
  }
  getLGACall(force?){
    if(force){
      this.listLGA();
    }
    else{
      this.storage.get('lga').then((data)=>{
        if(data){
          this.setLGA(data);
        }
        else{
          this.listLGA();
        }
      })
    }
  }
  listLGA(){
    this.getLGAs({}).subscribe((data:any)=>{
      if(data.status){
        this.setLGA(data.data)
        this.storage.set('lga',data.data).then((data)=>{
        })
      }
    })
  }
  getCandidateData(id,force?){
    if(!force){
      this.storage.get('candidate_'+id).then((data)=>{
        if(data){
          this.setCandidateValue(data);
        }
        else{
          this.getCandidateData(id,true);
        }
      })
    }
    else{
      this.getCandidate(id).subscribe((data:any)=>{
        if(data && data.data){
          this.setCandidateValue(data.data);
          this.storage.set('candidate_'+id,data.data).then((data)=>{})
        }
      })
    }
  }
  listInstitutions(){
    this.getInstitutions({}).subscribe((data:any)=>{
      var dt = data.data;
      if(dt){
        this.setInst(dt);
        this.storage.set('institutions',dt).then((data)=>{})
      }
    })
  }
  getInstitutionsCall(force?){
    if(force){
      this.listInstitutions()
    }
    else{
      this.storage.get('institutions').then((data)=>{
        if(data){
          this.setInst(data);
        }
        else{
          this.listInstitutions();
        }
      })
    }
  }
  listDepts(){
    this.getAllDepts().subscribe((data:any)=>{
      var dt = data.data;
      if(dt){
        this.setDept(dt);
        this.storage.set('depts',dt).then((data)=>{})
      }
    })
  }
  getDeptsCall(force?){
    if(force){
      this.storage.get('depts').then((data)=>{
        if(data){
          this.setDept(data);
        }
      })
      this.listDepts()
    }
    else{
      this.storage.get('depts').then((data)=>{
        if(data){
          this.setDept(data);
        }
        else{
          this.listDepts();
        }
      })
    }
  }
  listFaculty(){
    this.getAllFaculties({}).subscribe((data:any)=>{
      var dt = data.data;
      if(dt){
        this.setFaculty(dt);
        this.storage.set('faculties',dt).then((data)=>{})
      }
    })
  }
  getFacultiesCall(force?){
    if(force){
      this.storage.get('faculties').then((data)=>{
        if(data){
          this.setFaculty(data);
        }
      })
      this.listFaculty()
    }
    else{
      this.storage.get('faculties').then((data)=>{
        if(data){
          this.setFaculty(data);
        }
        else{
          this.listFaculty();
        }
      })
    }
  }
  listCourse(){
    this.GetCourses({}).subscribe((data:any)=>{
      var dt = data.data;
      if(dt){
        this.setCourse(dt);
        this.storage.set('courses',dt).then((data)=>{})
      }
    })
  }
  getCoursesCall(force?){
    if(force){
      this.storage.get('courses').then((data)=>{
        if(data){
          this.setCourse(data);
        }
      })
      this.listCourse()
    }
    else{
      this.storage.get('courses').then((data)=>{
        if(data){
          this.setCourse(data);
        }
        else{
          this.listCourse();
        }
      })
    }
  }
  listRoles(){
    this.getAllRoles({}).subscribe((data:any)=>{
      var dt = data.data;
      if(dt){
        this.setRoles(dt);
        this.storage.set('roles',dt).then((data)=>{})
      }
    })
  }
  getRolesCall(force?){
    if(force){
      this.storage.get('roles').then((data)=>{
        if(data){
          this.setRoles(data);
        }
      })
      this.listRoles()
    }
    else{
      this.storage.get('roles').then((data)=>{
        if(data){
          this.setRoles(data);
        }
        else{
          this.listRoles();
        }
      })
    }
  }
  listHow(){
    this.getAllHow({}).subscribe((data:any)=>{
      var dt = data.data;
      if(dt){
        this.setHow(dt);
        this.storage.set('how',dt).then((data)=>{})
      }
    })
  }
  getHowCall(force?){
    if(force){
      this.storage.get('how').then((data)=>{
        if(data){
          this.setHow(data);
        }
      })
      this.listHow()
    }
    else{
      this.storage.get('how').then((data)=>{
        if(data){
          this.setHow(data);
        }
        else{
          this.listHow();
        }
      })
    }
  }
  doLogout(type?){
    this.storage.remove(GlobalConstants.tokenName).then(()=>{
      this.presentToast('You have been logged out.');
      this.setValue(false);
      this.setProfile([]);
      GlobalConstants.token = '';
      if(this.getUrlPath3().includes('welcome'))
        return;
      this.loadPath('/sign-in');
      localStorage.removeItem('redir')
    })
  }
  loadError(error){
    console.log(error)
    if(!error.message)
      return;
    var msg = error.message.split('http');
    msg = msg[0];
    if(msg.length>1 && error.status<400){
      msg = msg + ' action. Ensure you have a working internet and try again!';
    }
    else if(msg.length>1 && (error.status==401)){
      error.statusText = 'Sorry, something went wrong';
      msg = (error?.error?.message)?error.error.message:msg;
      this.loadReplace('login')
      return;
    }
    else if(msg.length>1 && (error.status==403 || error.status==400)){
      error.statusText = 'Sorry, something went wrong';
      msg = (error?.error?.message)?error.error.message:msg;
    }
    else if(msg.length>1 && error.status>=400 && error.status<500){
      msg = msg + ' action. You are not authorized to perform action!';
    }
    else if(msg.length>1 && error.status>=500){
      msg = msg + ' action. Please contact admin to resolve issue!';
    }
    else
      msg = msg + ' Please try again!';
      this.presentToast(msg,'','danger');
  }
  async loadAlert(txt,header?){
    const alert = await this.alertCtrl.create({
      header: header?header:'Attention!',
      message: txt,
      buttons: [
        {
          text: 'Ok',
          role: 'okay',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }

  //mock server
  verifyCandidateByDonor(data){
    return this.http.post(`${this.api}VerifyCandidate`,data);
  }
  startDonation(data){
    return this.http.post(`${this.api}Donate`,data);
  }
  getTransactionStatus(id){
    return this.http.get(`${this.api}TransactionStatus/${id}`);
  }
  fetchProfileServer(){
    if(this.getUrlPath().includes('donate'))
    return;
    this.loadProfile().subscribe((data:any)=>{
      if(data.status){
        this.setProfile(data);
        if(data?.user?.role_name?.includes('student')){
          this.getCandidateData(data?.user?.id,true);
        }
      }
    },error=>{
      if(error.status==401){
        if(this.getUrlPath3().includes('donate') || this.getUrlPath3().includes('sponsor'))
          return;
        this.doLogout();
      }
      else if(error.status==403){
        if(this.getUrlPath3().includes('donate') || this.getUrlPath3().includes('sponsor'))
        return;
        this.presentToast('Action forbidden');
      }
    })
  }
  loadProfile(){
    return this.http.get(`${this.api}Profile`);
  }
  loadHomeStats(){
    return this.http.get(`${this.api}HomeStats`);
  }
  setNotice(data){
    return this.http.post(`${this.api}UpdateNote`,data);
  }
  getNotice(id){
    return this.http.get(`${this.api}Notifications/${id}`);
  }
  getReferrals(id){
    return this.http.get(`${this.api}Referals/${id}`);
  }
  getTransactionDetails(id){
    return this.http.get(`${this.api}TransactionDetails/${id}`);
  }
  setPin(data){
    return this.http.post(`${this.api}SetPin`,data);
  }
  Transfer(data){
    return this.http.post(`${this.api}Transfer`,data);
  }
  RemoveCard(id){
    return this.http.delete(`${this.api}Card/${id}`);
  }
  getAllRoles(data){
    return this.http.get(`${this.api}Roles`);
  }
  getAllHow(data){
    return this.http.get(`${this.api}How`);
  }
  Lender(data){
    return this.http.post(`${this.api}Lender`,data);
  }
  UpdateLender(data){
    return this.http.put(`${this.api}Lender`,data);
  }
  readAlert(item){
    if(item.status=='1')
      return;
    this.setNotice({status:1,id:item.id}).subscribe((data:any)=>{
      if(data.status){
        this.getProfileData(true);
      }
    })
  }
  GetTuitionFee(data){
    return this.http.post(`${this.api}GetTuitionFee`,data);
  }
  DeleteLoan(id){
    return this.http.delete(`${this.api}Loans/${id}`);
  }
  DeleteLoans(data){
    return this.http.post(`${this.api}Loans`,data);
  }
  gettLGA(data){
    return this.http.post(`${this.api}LGA`,data);
  }
  GetCourses(data){
    return this.http.post(`${this.api}Courses`,data);
  }
  CreateLoan(data){
    return this.http.post(`${this.api}LenderLoan`,data);
  }
  UpdateLoan(data){
    return this.http.put(`${this.api}LenderLoan`,data);
  }
  GetLoan(id){
    return this.http.get(`${this.api}LenderLoan/${id}`);
  }
  GetStudentLoan(id){
    return this.http.get(`${this.api}Loans/${id}`);
  }
  DeleteLenderLoan(id){
    return this.http.delete(`${this.api}LenderLoan/${id}`);
  }
  LenderPayLoan(data){
    return this.http.post(`${this.api}LenderPayLoan`,data);
  }
  Institution(data){
    return this.http.post(`${this.api}Institutions`,data);
  }
  UpdateInstitution(data){
    return this.http.put(`${this.api}Institutions`,data);
  }
  deleteCandidates(param){
    return this.http.post(`${this.api}DeleteCandidates`,param);
  }
  deleteCandidate(id){
    return this.http.delete(`${this.api}DeleteCandidates/${id}`);
  }
  removeRef(id){
    return this.http.delete(`${this.api}Candidate/RemoveReferee/${id}`);
  }
  addReferee(data){
    return this.http.post(`${this.api}Referee/Add`,data);
  }
  updateReferee(data){
    return this.http.post(`${this.api}Referee/Update`,data);
  }
  verifyStudent(data){
    return this.http.put(`${this.api}verifyStudent`,data);
  }
  AddAgent(data){
    return this.http.post(`${this.api}Agents`,data);
  }
  editAgent(data){
    return this.http.put(`${this.api}Agents`,data);
  }
  DeleteAgent(id){
    return this.http.delete(`${this.api}DeleteAgents/${id}`);
  }
  DeleteAgents(data){
    return this.http.post(`${this.api}DeleteAgents`,data);
  }
  getDeptsAdmin(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Department/${count}/${page}`,{params:type});
  }
  DeleteDepts(data){
    return this.http.post(`${this.api}DeleteDepartments`,data);
  }
  AddDept(data){
    return this.http.post(`${this.api}Departments`,data);
  }
  editDept(data){
    return this.http.put(`${this.api}Departments`,data);
  }
  getFacAdmin(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Faculties/${count}/${page}`,{params:type});
  }
  DeleteFac(data){
    return this.http.post(`${this.api}DeleteFaculties`,data);
  }
  AddFaculty(data){
    return this.http.post(`${this.api}Faculties`,data);
  }
  editFaculty(data){
    return this.http.put(`${this.api}Faculties`,data);
  }
  getInstAdmin(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Institutions/${count}/${page}`,{params:type});
  }
  DeleteInst(data){
    return this.http.post(`${this.api}DeleteInstitutions`,data);
  }
  AddInstitution(data){
    return this.http.post(`${this.api}Institutions`,data);
  }
  editInstitution(data){
    return this.http.put(`${this.api}Institutions`,data);
  }
  getVRs(type,count,page){
    if(!type)
    type = '';
    return this.http.get(`${this.api}VerificationRequests/${count}/${page}`,{params:type});
  }
  deleteVR(id){
    return this.http.delete(`${this.api}VerificationRequests/${id}`);
  }
  deleteVRs(data){
    return this.http.post(`${this.api}DeleteVerificationRequests/`,data);
  }
  getLenders(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Lenders/${count}/${page}`,{params:type});
  }
  getLender(id){
    return this.http.get(`${this.api}Lenders/${id}`);
  }
  updateLenderLoan(data){
    return this.http.put(`${this.api}LenderLoan`,data);
  }
  DeleteLenders(data){
    return this.http.post(`${this.api}Lenders`,data);
  }
  getAdminUsers(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Users/${count}/${page}`,{params:type});
  }
  getAdminUser(id){
    return this.http.get(`${this.api}Users/${id}`);
  }
  updateAdminUser(data){
    return this.http.put(`${this.api}Users`,data);
  }
  AddAdminUser(data){
    return this.http.post(`${this.api}Users`,data);
  }
  DeleteUser(data){
    return this.http.post(`${this.api}DeleteUsers`,data);
  }
  AddGroup(data){
    return this.http.post(`${this.api}Group`,data);
  }
  UpdateGroup(data){
    return this.http.put(`${this.api}Group`,data);
  }
  DeleteGroup(data){
    return this.http.post(`${this.api}DeleteGroup`,data);
  }
  getGroups(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Group/${count}/${page}`,{params:type});
  }
  getPermissions(data){
    return this.http.post(`${this.api}Permissions`,data);
  }
  Parent(data){
    return this.http.post(`${this.api}Parents`,data);
  }
  UpdateParent(data){
    return this.http.put(`${this.api}Parents`,data);
  }
  getSkillProfile(id){
    return this.http.get(`${this.api}Jobs/Profile/${id}`);
  }
  saveSkillProfile(data){
    return this.http.post(`${this.api}Jobs/Profile`,data);
  }
  getAllocations(id){
    return this.http.get(`${this.api}Candidate/Allocations/${id}`);
  }
  getHMOs(id){
    return this.http.get(`${this.api}Subscriptions/${id}`);
  }
  getHealthCentres(data){
    return this.http.post(`${this.api}HMOCentres`,data);
  }
  subHMO(data){
    return this.http.post(`${this.api}SubscribeHMO`,data);
  }
  getInsurance(id){
    return this.http.get(`${this.api}InsuranceSubscriptions/${id}`);
  }
  subInsurance(data){
    return this.http.post(`${this.api}SubscribeInsurance`,data);
  }
  getWallets(type,count,page){
    if(!type)
    type = '';
    return this.http.get(`${this.api}Wallets/${count}/${page}`,{params:type});
  }
  updateWallet(data){
    return this.http.put(`${this.api}Wallet/`,data);
  }
  deletWallet(data){
    return this.http.post(`${this.api}DeleteWallet/`,data);
  }
  getWalletHistory(type,count,page){
    if(!type)
    type = '';
    return this.http.get(`${this.api}WalletHistories/${count}/${page}`,{params:type});
  }
  deletWalletHistory(data){
    return this.http.post(`${this.api}DeleteWalletHistory/`,data);
  }
  getWards(id){
    return this.http.get(`${this.api}Parents/Wards/${id}`);
  }
  addWard(data){
    return this.http.post(`${this.api}Parents/Wards`,data);
  }
  requestTution(id){
    return this.http.get(`${this.api}Parents/RequestTuition/${id}`);
  }
  creditWard(data){
    return this.http.post(`${this.api}Parents/CreditWard`,data);
  }
  RemoveWard(id){
    return this.http.get(`${this.api}Parents/RemoveWard/${id}`);
  }
  FcmbCreateAccount(data){
    return this.http.post(`${this.api}Fc/createAccount`,data);
  }
  FcmbCreateWallet(data){
    return this.http.post(`${this.api}Fc/createWallet`,data);
  }
  TellStory(data){
    return this.http.post(`${this.api}Candidate/TellStory`,data);
  }
  acceptParent(data){
    return this.http.post(`${this.api}Candidate/AcceptParent`,data);
  }
  rejectParent(data){
    return this.http.post(`${this.api}Candidate/RejectParent`,data);
  }
  AddHMO(data){
    return this.http.post(`${this.api}HMO/Add`,data);
  }
  doVerify(data){
    return this.http.post(`${this.api}VerificationRequests`,data);
  }
  updatePayR(data){
    return this.http.post(`${this.api}UpdatePayRequest`,data);
  }
  applyScholarship(data){
    return this.http.post(`${this.api}applyScholarship`,data);
  }
  verifyRRR(id){
    return this.http.get(`${this.api}Remita/RRR/${id}`);
  }
  sendRRR(data){
    return this.http.post(`${this.api}Remita/RRR`,data);
  }
  getScholarships(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Scholarships/${count}/${page}`,{params:type});
  }
  editScholarship(data){
    return this.http.put(`${this.api}Scholarships`,data);
  }
  DeleteScholarship(id){
    return this.http.delete(`${this.api}Scholarships/Delete/${id}`);
  }
  DeleteScholarships(data){
    return this.http.post(`${this.api}Scholarships/Delete`,data);
  }
  getCustomer(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Users/${count}/${page}`,{params:type});
  }
  editCustomer(data){
    return this.http.put(`${this.api}Users`,data);
  }
  DeleteCustomer(id){
    return this.http.delete(`${this.api}Users/Delete/${id}`);
  }
  DeleteCustomers(data){
    return this.http.post(`${this.api}Users/Delete`,data);
  }
  getAllUsers(data){
    if(!data)
     data = {};
   return this.http.get(`${this.api}Users`,{params:data});
  }
  previewBill(data){
    return this.http.post(`${this.api}Bills/Preview`,data);
  }
  payBill(data){
    return this.http.post(`${this.api}Bills/Pay`,data);
  }
  getJobs(count,page,type){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Jobs/${count}/${page}`,{params:type});
  }
  getJob(data){
    return this.http.post(`${this.api}Jobs/View`,data);
  }
  applyJob(data){
    return this.http.post(`${this.api}Jobs/Apply`,data);
  }
  getJobApps(count,page,type){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Jobs/Applications/${count}/${page}`,{params:type});
  }
  getAlerts(count,page,data){
    return this.http.post(`${this.api}users/alerts/${count}/${page}`,data);
  }
  contact(data){
    return this.http.post(`${this.api}major/contact`,data);
  }
  getTransactionsAdmin(type,count,page){
    if(!type)
    type = '';
    return this.http.get(`${this.api}Transactions/${count}/${page}`,{params:type});
  }
  getTransactions(count,page,data){
    return this.http.post(`${this.api}bills/${count}/${page}`,data);
  }
  getTransaction(data){
    return this.http.post(`${this.api}bills/view`,data);
  }
  deleteTransaction(data){
    return this.http.post(`${this.api}bills/delete`,data);
  }
  EditTransaction(data){
    return this.http.post(`${this.api}Transactions/Update`,data);
  }
  rePayBill(data){
    return this.http.post(`${this.api}bills/re_pay`,data);
  }
  saveTrans(data){
    return this.http.post(`${this.api}VerificationRequests/saveTrans`,data);
  }
  getVirtual(data){
    return this.http.post(`${this.api}CreateWallet`,data);
  }
  getBalance(data){
    return this.http.post(`${this.api}Fc/Balance`,data);
  }
  DeleteAccounts(data){
    return this.http.post(`${this.api}Fc/DeleteAccounts`,data);
  }
  getAccounts(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Fc/Accounts/${count}/${page}`,{params:type});
  }
  DeleteAttempts(data){
    return this.http.post(`${this.api}Fc/DeleteAttempts`,data);
  }
  getAttempts(type,count,page){
    if(!type) type = '';
    return this.http.get(`${this.api}Fc/Attempts/${count}/${page}`,{params:type});
  }
  getLoanAttempts(type,count,page){
    if(!type) type = '';
    return this.http.get(`${this.api}Loans/Attempts/${count}/${page}`,{params:type});
  }
  DeleteFund(data){
    return this.http.post(`${this.api}Donations/Delete`,data);
  }
  getFund(type,count,page){
    if(!type)
      type = '';
    return this.http.get(`${this.api}Donations/FYFs/${count}/${page}`,{params:type});
  }
  AddFYF(data){
    return this.http.post(`${this.api}Donations/AddFYF`,data);
  }
  EditFYF(data){
    return this.http.post(`${this.api}Donations/EditFYF`,data);
  }
  DeleteFYF(data){
    return this.http.post(`${this.api}Donations/DeleteFYF`,data);
  }
  getFYFs(count,page,data){
    return this.http.get(`${this.api}Donations/FYFs/${count}/${page}`,data);
  }
  getFYF(data){
    return this.http.post(`${this.api}Donations/ViewFYF`,data);
  }
  addStudent(data){
    return this.http.post(`${this.api}Donations/addStudent`,data);
  }
  removeStudent(data){
    return this.http.post(`${this.api}Donations/removeStudent`,data);
  }
  startDonationFYF(data){
    return this.http.post(`${this.api}Donate/FYF`,data);
  }
  getDonationsFYF(data){
    return this.http.post(`${this.api}Donations/FYFDonors`,data);
  }
  referrals(id?){
    return this.http.get(`${this.api}Referrals`);
  }
  getWRs(type,count,page){
    if(!type) type = '';
    return this.http.get(`${this.api}WithdrawalRequests/${count}/${page}`,{params:type});
  }
  deleteWR(id){
    return this.http.delete(`${this.api}WithdrawalRequests/${id}`);
  }
  deleteWRs(data){
    return this.http.post(`${this.api}DeleteWithdrawalRequests/`,data);
  }
  getBanks(data?){
    return this.http.post(`${this.api}Banks`,data);
  }
  getSavingPlans(type,count,page){
    if(!type) type = '';
    return this.http.get(`${this.api}SavingPlans/${count}/${page}`,{params:type});
  }
  deleteSavingsPlan(data){
    return this.http.post(`${this.api}DeleteSavingPlans/`,data);
  }
  getReferees(data?){
    return this.http.get(`${this.api}Users/Referees`,{params:data});
  }
  getDocuments(data?){
    return this.http.get(`${this.api}Users/Docs`,{params:data});
  }
  getCreditReport(id,data?){
    return this.http.post(`${this.api}Credit/${id}`,data);
  }
}