import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReloadServiceService {
  private regLevel:BehaviorSubject<any>;
  private activeMenu:BehaviorSubject<any>;
  private resendRefMenu:BehaviorSubject<any>;
  private sideMenuWide:BehaviorSubject<any>;
  private homeStats:BehaviorSubject<any>;
  private pageStats:BehaviorSubject<any>;
  private reloadHome:BehaviorSubject<any>;
  private isMobile:BehaviorSubject<any>;

  constructor() {
    this.regLevel = new BehaviorSubject<any>(0);
    this.activeMenu = new BehaviorSubject<any>('');
    this.resendRefMenu = new BehaviorSubject<any>(false);
    this.sideMenuWide = new BehaviorSubject<any>(true);
    this.homeStats = new BehaviorSubject<any>([]);
    this.pageStats = new BehaviorSubject<any>([]);
    this.reloadHome = new BehaviorSubject<any>(false);
    this.isMobile = new BehaviorSubject<any>(true);
  }
  getReloadHomeStats(): Observable<boolean> {
    return this.reloadHome.asObservable();
  }
  setReloadHomeStats(newValue): void {
    this.reloadHome.next(newValue);
  }
  getPageStats(): Observable<boolean> {
    return this.pageStats.asObservable();
  }
  setPageStats(newValue): void {
    this.pageStats.next(newValue);
  }
  getHomeStats(): Observable<boolean> {
    return this.homeStats.asObservable();
  }
  setHomeStats(newValue): void {
    this.homeStats.next(newValue);
  }
  getRegLevel(): Observable<boolean> {
    return this.regLevel.asObservable();
  }
  setRegLevel(newValue): void {
    this.regLevel.next(newValue);
  }
  getActiveMenu(): Observable<boolean> {
    return this.activeMenu.asObservable();
  }
  setActiveMenu(newValue): void {
    this.activeMenu.next(newValue);
  }
  getResendRef(): Observable<boolean> {
    return this.resendRefMenu.asObservable();
  }
  setResendRef(newValue): void {
    this.resendRefMenu.next(newValue);
  }
  getSideMenuWide(): Observable<boolean> {
    return this.sideMenuWide.asObservable();
  }
  setSideMenuWide(newValue): void {
    this.sideMenuWide.next(newValue);
  }
  getMobileView(): Observable<boolean> {
    return this.isMobile.asObservable();
  }
  setMobileView(newValue): void {
    this.isMobile.next(newValue);
  }
}
