import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { Globals } from 'highcharts';
import { GlobalConstants } from 'src/app/common/global-constants';
import { DataService } from 'src/app/services/data-service.service';
import { FunctionServiceService } from 'src/app/services/function-service.service';
import { ReloadServiceService } from 'src/app/services/reload-service.service';
var files,formData;
@Component({
  selector: 'app-story',
  templateUrl: './story.page.html',
  styleUrls: ['./story.page.scss'],
})
export class StoryPage implements OnInit {
  myForm:FormGroup;userData;isLoading;data;sessions = [];candidate;isLoadingData;file;inv;video;resource;
  fileInv;fileAd;fileSize = 10;fileDuration = 1;disabled;
  constructor(
    private fb:FormBuilder,
    private ds:DataService,
    private fn:FunctionServiceService,
    private rl:ReloadServiceService,
    private modalCtrl:ModalController,
    private alertCtrl:AlertController
  ) { }

  ngOnInit() {
    this.reactiveForm();
    this.ds.getResValue().subscribe((data)=>{
      this.resource = data;
    })
    this.ds.getProfileValue().subscribe((data)=>{
      this.userData = data;
    })
    if(this.data){
      this.myForm.patchValue({
        tuitionfee:this.data?.tutionfee,
        session:this.data?.session,
        id:this.data?.id,
      })
    }
    this.ds.getCandidateValue().subscribe((data:any)=>{
      if(data?.story){
        this.myForm.patchValue(data?.story)
      }
    })
  }
  getCandidate(type?,data?){
  }
  reactiveForm(){
    var form = {
      'descr':['',[]],
      'video':['',[]],
      'video_x':['',[]],
    };
    if(this.data){
      form['id'] = ['',[Validators.required]];
    }
    this.myForm = this.fb.group(form)
  }
  public errorHandling = (control: string, error: string) => {
    if(this.myForm && this.myForm.controls && this.myForm.controls[control])
    return this.myForm.controls[control].hasError(error);
  }
  getTuitionFee(){
    this.ds.GetTuitionFee(this.myForm.value).subscribe((data:any)=>{})
  }
  async confirm(text,header?,term?){
    const alert = await this.alertCtrl.create({
      message:text,
      header:header?header:'Confirm Action',
      buttons:[
        {
          role:'cancel',
          text:'Cancel',
          handler:()=>{},
        },
        {
          role:'ok',
          text:'Pay With My Bank Account',
          handler:()=>{
            this.submit();
          },
        }
      ]
    })
    alert.onDidDismiss().then((data)=>{
      if(data?.role=='cancel'){
        this.closeModal();
      }
      else if(data?.role=='ok'){
        this.fn.presentToast('Request is being processed');
      }
    })
    await alert.present();
  }
  submit(){
    if(this.myForm.valid){
      this.setFiles();
      this.isLoading = true;
      var link = 'TellStory';
      this.ds[link](formData).subscribe((data:any)=>{
        this.isLoading = false;
        if(data.status){
          this.fn.loadAlert(data.message,'Thank you, all Done!');
          this.closeModal(true);
        }
        else{
          this.fn.loadAlert(data.message,'Eh yah, sorry!')
        }
      },error=>{
        this.isLoading = false;
        this.fn.loadError(error);
      })
    }
  }
  closeModal(data?){
    this.modalCtrl.dismiss(data);
  }
  doRefresh(event){
    this.ngOnInit();
    setTimeout(()=>{
      event.target.complete();
    },2000)
  }
  changeListener($event) : void {
    files = $event.target.files;
    this.fileAd = this.file = $event.target.files[0];
    this.setFiles(1);
    this.fileAd = this.file = $event.target.files[0];
    var size = $event.target.files[0].size;
    if((size/(1000*1000))>this.fileSize){
      this.fn.loadAlert(`Maximum of ${this.fileSize}MB is allowed for upload. You uploaded a video with a size of `+(size/(1000*1000)).toFixed(2)+`MB`);
      return;
    }
    let reader = new FileReader();
    reader.onload = ($event:any) => {
      this.video = $event.target.result;
      let blobURL = URL.createObjectURL(this.fileAd);
      this.myForm.patchValue({
        //video:this.video,
        //video_x:this.video?this.video.split(';')[0].split('data:')[1]:''
      });
    }
    reader.readAsDataURL($event.target.files[0]);
  }
  setFiles(type?){
    formData = new FormData();
    var fd = [];
    formData.append('model', JSON.stringify(this.myForm.value)); // Add any other data you want to send
    if(this.fileAd)
      formData.append('video', this.fileAd, this.fileAd.name);
    if(this.fileInv)
      formData.append('inv', this.fileInv, this.fileInv.name);
  }
  duration;
  onMetadata(e, video) {
    this.duration = video.duration;
    this.disabled = false;
    if(this.duration/60>this.fileDuration && GlobalConstants.baseUrl.includes('https')){
      this.disabled = true;
      this.fn.loadAlert('Video must not be more than 1 minute');
    }
  }

}