import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'overview',
    //loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'reset-account',
    loadChildren: () => import('./pages/reset/reset.module').then( m => m.ResetPageModule)
  },
  {
    path: 'verify-account',
    loadChildren: () => import('./pages/verify-account/verify-account.module').then( m => m.VerifyAccountPageModule)
  },
  {
    path: 'set-profile',
    loadChildren: () => import('./pages/set-profile/set-profile.module').then( m => m.SetProfilePageModule)
  },
  {
    path: 'overview',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'profile-information',
    loadChildren: () => import('./pages/registration-form/registration-form.module').then( m => m.RegistrationFormPageModule)
  },
  {
    path: 'loans',
    loadChildren: () => import('./pages/loans/loans.module').then( m => m.LoansPageModule)
  },
  {
    path: 'loan/:id',
    loadChildren: () => import('./pages/loans/loan/loan.module').then( m => m.LoanPageModule)
  },
  {
    path: 'savings',
    loadChildren: () => import('./pages/savings/savings.module').then( m => m.SavingsPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'top-up',
    loadChildren: () => import('./pages/actions/top-up/top-up.module').then( m => m.TopUpPageModule)
  },
  {
    path: 'pay',
    loadChildren: () => import('./pages/actions/pay/pay.module').then( m => m.PayPageModule)
  },
  {
    path: 'send',
    loadChildren: () => import('./pages/actions/send/send.module').then( m => m.SendPageModule)
  },
  {
    path: 'request',
    loadChildren: () => import('./pages/actions/request/request.module').then( m => m.RequestPageModule)
  },
  {
    path: 'refer',
    loadChildren: () => import('./pages/actions/refer/refer.module').then( m => m.ReferPageModule)
  },
  {
    path: 'withdraw',
    loadChildren: () => import('./pages/actions/withdraw/withdraw.module').then( m => m.WithdrawPageModule)
  },
  {
    path: 'request-loans',
    loadChildren: () => import('./pages/actions/request-loans/request-loans.module').then( m => m.RequestLoansPageModule)
  },
  {
    path: 'opt-out',
    loadChildren: () => import('./pages/actions/opt-out/opt-out.module').then( m => m.OptOutPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'pay-bills',
    loadChildren: () => import('./pages/actions/pay-bills/pay-bills.module').then( m => m.PayBillsPageModule)
  },
  {
    path: 'recurrent-savings',
    loadChildren: () => import('./pages/actions/recurrent-savings/recurrent-savings.module').then( m => m.RecurrentSavingsPageModule)
  },
  {
    path: 'donations',
    loadChildren: () => import('./pages/donations/donations.module').then( m => m.DonationsPageModule)
  },
  {
    path: 'sponsorships',
    loadChildren: () => import('./pages/donations/public-donations/public-donations.module').then( m => m.PublicDonationsPageModule)
  },
  {
    path: 'sponsorships/:id',
    loadChildren: () => import('./pages/donations/public-donations/public-donations.module').then( m => m.PublicDonationsPageModule)
  },
  {
    path: 'wallet-transfer',
    loadChildren: () => import('./pages/actions/wallet-transfer/wallet-transfer.module').then( m => m.WalletTransferPageModule)
  },
  {
    path: 'donate',
    loadChildren: () => import('./pages/actions/donate/donate.module').then( m => m.DonatePageModule)
  },
  {
    path: 'donate/:id',
    loadChildren: () => import('./pages/actions/donate/donate.module').then( m => m.DonatePageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'set-pin',
    loadChildren: () => import('./pages/set-pin/set-pin.module').then( m => m.SetPinPageModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('./pages/cards/cards.module').then( m => m.CardsPageModule)
  },
  {
    path: 'lender-information',
    loadChildren: () => import('./pages/forms/lender-information/lender-information.module').then( m => m.LenderInformationPageModule)
  },
  {
    path: 'request-verification',
    loadChildren: () => import('./pages/request-verify/request-verify.module').then( m => m.RequestVerifyPageModule)
  },
  {
    path: 'lend-out',
    loadChildren: () => import('./pages/actions/lend-out/lend-out.module').then( m => m.LendOutPageModule)
  },
  {
    path: 'lend-out/:id',
    loadChildren: () => import('./pages/actions/lend-out/lend-out.module').then( m => m.LendOutPageModule)
  },
  {
    path: 'loan-transaction/:id',
    loadChildren: () => import('./pages/actions/lend-out/view-loan-tran/view-loan-tran.module').then( m => m.ViewLoanTranPageModule)
  },
  {
    path: 'institution-information',
    loadChildren: () => import('./pages/forms/institution-information/institution-information.module').then( m => m.InstitutionInformationPageModule)
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./pages/admin/admin-dashboard/admin-dashboard.module').then( m => m.AdminDashboardPageModule)
  },
  {
    path: 'admin-students',
    loadChildren: () => import('./pages/admin/admin-students/admin-students.module').then( m => m.AdminStudentsPageModule)
  },
  {
    path: 'admin-academic-records',
    loadChildren: () => import('./pages/admin/admin-students/academic-records/academic-records.module').then( m => m.AcademicRecordsPageModule)
  },
  {
    path: 'admin-loans',
    loadChildren: () => import('./pages/admin/admin-loans/admin-loans.module').then( m => m.AdminLoansPageModule)
  },
  {
    path: 'admin-agents',
    loadChildren: () => import('./pages/admin/admin-agents/admin-agents.module').then( m => m.AdminAgentsPageModule)
  },
  {
    path: 'admin-vrs',
    loadChildren: () => import('./pages/admin/admin-vrs/admin-vrs.module').then( m => m.AdminVrsPageModule)
  },
  {
    path: 'admin-depts',
    loadChildren: () => import('./pages/admin/admin-depts/admin-depts.module').then( m => m.AdminDeptsPageModule)
  },
  {
    path: 'admin-faculties',
    loadChildren: () => import('./pages/admin/admin-fac/admin-fac.module').then( m => m.AdminFacPageModule)
  },
  {
    path: 'admin-institutions',
    loadChildren: () => import('./pages/admin/admin-institutions/admin-institutions.module').then( m => m.AdminInstitutionsPageModule)
  },
  {
    path: 'slider',
    loadChildren: () => import('./pages/slider/slider.module').then( m => m.SliderPageModule)
  },
  {
    path: 'admin-lenders',
    loadChildren: () => import('./pages/admin/admin-lenders/admin-lenders.module').then( m => m.AdminLendersPageModule)
  },
  {
    path: 'admin-permissions/:id',
    loadChildren: () => import('./pages/admin/admin-permission/admin-permission.module').then( m => m.AdminPermissionPageModule)
  },
  {
    path: 'admin-users',
    loadChildren: () => import('./pages/admin/admin-users/admin-users.module').then( m => m.AdminUsersPageModule)
  },
  {
    path: 'admin-groups',
    loadChildren: () => import('./pages/admin/admin-groups/admin-groups.module').then( m => m.AdminGroupsPageModule)
  },
  {
    path: 'parent-information',
    loadChildren: () => import('./pages/forms/parent-information/parent-information.module').then( m => m.ParentInformationPageModule)
  },
  {
    path: 'skill-job-profile',
    loadChildren: () => import('./pages/forms/skill-job-profile/skill-job-profile.module').then( m => m.SkillJobProfilePageModule)
  },
  {
    path: 'monthly-allocations',
    loadChildren: () => import('./pages/monthly-allocations/monthly-allocations.module').then( m => m.MonthlyAllocationsPageModule)
  },
  {
    path: 'hmo',
    loadChildren: () => import('./pages/hmo/hmo.module').then( m => m.HmoPageModule)
  },
  {
    path: 'insurance',
    loadChildren: () => import('./pages/insurance/insurance.module').then( m => m.InsurancePageModule)
  },
  {
    path: 'request-loan-parent',
    loadChildren: () => import('./pages/actions/request-loan-parent/request-loan-parent.module').then( m => m.RequestLoanParentPageModule)
  },
  {
    path: 'admin-wallets',
    loadChildren: () => import('./pages/admin/admin-wallets/admin-wallets.module').then( m => m.AdminWalletsPageModule)
  },
  {
    path: 'wards',
    loadChildren: () => import('./pages/wards/wards.module').then( m => m.WardsPageModule)
  },
  {
    path: 'academic-records',
    loadChildren: () => import('./pages/academic-records/academic-records.module').then( m => m.AcademicRecordsPageModule)
  },
  {
    path: 'my-referees',
    loadChildren: () => import('./pages/my-referees/my-referees.module').then( m => m.MyRefereesPageModule)
  },
  {
    path: 'pay-my-tuition',
    loadChildren: () => import('./pages/actions/pay-my-tuition/pay-my-tuition.module').then( m => m.PayMyTuitionPageModule)
  },
  {
    path: 'tuition-requests',
    loadChildren: () => import('./pages/academic-records/tuition-requests/tuition-requests.module').then( m => m.TuitionRequestsPageModule)
  },
  {
    path: 'bio',
    loadChildren: () => import('./pages/profiles/bio/bio.module').then( m => m.BioPageModule)
  },
  {
    path: 'iden',
    loadChildren: () => import('./pages/profiles/iden/iden.module').then( m => m.IdenPageModule)
  },
  {
    path: 'edurecords',
    loadChildren: () => import('./pages/profiles/edurecords/edurecords.module').then( m => m.EdurecordsPageModule)
  },
  {
    path: 'bankinfo',
    loadChildren: () => import('./pages/profiles/bankinfo/bankinfo.module').then( m => m.BankinfoPageModule)
  },
  {
    path: 'refereeinfo',
    loadChildren: () => import('./pages/profiles/refereeinfo/refereeinfo.module').then( m => m.RefereeinfoPageModule)
  },
  {
    path: 'profile-mgt',
    loadChildren: () => import('./pages/profiles/profile-mgt/profile-mgt.module').then( m => m.ProfileMgtPageModule)
  },
  {
    path: 'addr-info',
    loadChildren: () => import('./pages/profiles/addr-info/addr-info.module').then( m => m.AddrInfoPageModule)
  },
  {
    path: 'locate-hospital',
    loadChildren: () => import('./pages/hmo/locate-hospital/locate-hospital.module').then( m => m.LocateHospitalPageModule)
  },
  {
    path: 'my-parent-guardian',
    loadChildren: () => import('./pages/my-parents/my-parents.module').then( m => m.MyParentsPageModule)
  },
  {
    path: 'admin-pay-request',
    loadChildren: () => import('./pages/admin/admin-pay-request/admin-pay-request.module').then( m => m.AdminPayRequestPageModule)
  },
  {
    path: 'scholarships',
    loadChildren: () => import('./pages/scholarships/scholarships.module').then( m => m.ScholarshipsPageModule)
  },
  {
    path: 'apply-for-scholarship',
    loadChildren: () => import('./pages/scholarships/scholarships.module').then( m => m.ScholarshipsPageModule)
  },
  {
    path: 'verify-rrr',
    loadChildren: () => import('./pages/admin/rrr/verify-rrr/verify-rrr.module').then( m => m.VerifyRRRPageModule)
  },
  {
    path: 'admin-scholarships',
    loadChildren: () => import('./pages/admin/admin-scholarships/admin-scholarships.module').then( m => m.AdminScholarshipsPageModule)
  },
  {
    path: 'admin-customers',
    loadChildren: () => import('./pages/admin/admin-customers/admin-customers.module').then( m => m.AdminCustomersPageModule)
  },
  {
    path: 'view-alert',
    loadChildren: () => import('./pages/view-alert/view-alert.module').then( m => m.ViewAlertPageModule)
  },
  {
    path: 'bills',
    loadChildren: () => import('./pages/bills/bills.module').then( m => m.BillsPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./pages/jobs/jobs.module').then( m => m.JobsPageModule)
  },
  {
    path: 'jobs/:id',
    loadChildren: () => import('./pages/jobs/view-job/view-job.module').then( m => m.ViewJobPageModule)
  },
  {
    path: 'alerts',
    loadChildren: () => import('./pages/alerts/alerts.module').then( m => m.AlertsPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'admin-wallet-history',
    loadChildren: () => import('./pages/admin/admin-wallet-history/admin-wallet-history.module').then( m => m.AdminWalletHistoryPageModule)
  },
  {
    path: 'bills/transaction/:id',
    loadChildren: () => import('./pages/bills/transactions/transaction/transaction.module').then( m => m.TransactionPageModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('./pages/partners/partners.module').then( m => m.PartnersPageModule)
  },
  {
    path: 'admin-fcmb',
    loadChildren: () => import('./pages/admin/admin-fcmb/admin-fcmb.module').then( m => m.AdminFcmbPageModule)
  },
  {
    path: 'admin-fund-your-future',
    loadChildren: () => import('./pages/admin/admin-fund-your-future/admin-fund-your-future.module').then( m => m.AdminFundYourFuturePageModule)
  },
  {
    path: 'fund-your-future',
    loadChildren: () => import('./pages/fund-your-future/fund-your-future.module').then( m => m.FundYourFuturePageModule)
  },
  {
    path: 'donate/fund-your-future/:id',
    loadChildren: () => import('./pages/fund-your-future/list-fund-your-future/list-fund-your-future.module').then( m => m.ListFundYourFuturePageModule)
  },
  {
    path: 'admin-transactions',
    loadChildren: () => import('./pages/admin/admin-transactions/admin-transactions.module').then( m => m.AdminTransactionsPageModule)
  },
  {
    path: 'admin-fcmb-attempts',
    loadChildren: () => import('./pages/admin/fcmb-attempts/fcmb-attempts.module').then( m => m.FcmbAttemptsPageModule)
  },
  {
    path: 'referals',
    loadChildren: () => import('./pages/referals/referals.module').then( m => m.ReferalsPageModule)
  },
  {
    path: 'admin-lifecycle',
    loadChildren: () => import('./pages/admin/user-lifecycle/user-lifecycle.module').then( m => m.UserLifecyclePageModule)
  },
  {
    path: 'admin-tasks',
    loadChildren: () => import('./pages/admin/tasks/tasks.module').then( m => m.TasksPageModule)
  },
  {
    path: 'admin-loan-attempts',
    loadChildren: () => import('./pages/admin/admin-loans/admin-loan-attempts/admin-loan-attempts.module').then( m => m.AdminLoanAttemptsPageModule)
  },
  {
    path: 'admin-living-expense-requests',
    loadChildren: () => import('./pages/admin/admin-withdrawal-requests/admin-withdrawal-requests.module').then( m => m.AdminWithdrawalRequestsPageModule)
  },
  {
    path: 'admin-recurring-savings',
    loadChildren: () => import('./pages/admin/admin-recurring/admin-recurring.module').then( m => m.AdminRecurringPageModule)
  },
  //{
    //path: '**',
    //redirectTo: 'overview',
    //pathMatch: 'full'
  //},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
