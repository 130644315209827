import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router, ROUTER_INITIALIZER } from '@angular/router';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { GlobalConstants } from './common/global-constants';
import { StoryPage } from './pages/donations/story/story.page';
import { DataService } from './services/data-service.service';
import { FunctionServiceService } from './services/function-service.service';
import { ReloadServiceService } from './services/reload-service.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';
import { BackgroundColorOptions, StatusBar, StatusBarInfo, Style } from '@capacitor/status-bar';
import { AnalyticsService } from './services/analytics.service';
import OneSignal from 'onesignal-cordova-plugin';
import { ViewAlertPage } from './pages/view-alert/view-alert.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild('page') page: ElementRef;
  isSettled = 0;activeMenu;pageT;page2;isMobile:any;isApp:any;
  menus = GlobalConstants.menuArray;isReg;regMenus:any = [];
  currentNav:any;isWide = '1';isRegPage = true;userData:any;
  lenderArray = GlobalConstants.lenderArray;uniArray = GlobalConstants.uniArray;isAdmin;
  loadCandidate = 0;isMenu:any;resource:any;currentUrl:any;sessions:any = [];
  constructor(
    private ds:DataService,
    private fn:FunctionServiceService,
    private rl:ReloadServiceService,
    private platform:Platform,
    private storage:Storage,
    private router:Router,
    private toastCtrl:ToastController,
    private alertCtrl:AlertController,
    private modalCtrl:ModalController,
    private analytics:AnalyticsService
  ){
    this.initializeApp();
  }
  isOn:boolean = true;isOnCount = 0;doReload:boolean = false;student;notify = false;
  initializeApp() {
    setInterval(()=>{
    },1000);
    setInterval(()=>{
      this.isOn = false;
      this.isOnCount++;
      if(this.isOnCount>6){
        //window.location.reload();
        this.doReload = true;
      }
    },10000)
    this.platform.resume.subscribe((data)=>{
      this.initializeApp()
    })
    this.platform.pause.subscribe((data)=>{
    })
    if(this.platform.is('capacitor') || this.platform.is('android')){
      App.getInfo().then((data:any)=>{
        //this.fn.presentToast(JSON.stringify(data));
        GlobalConstants.appVersion = data?.version;
      })
    }
    this.platform.ready().then(() => {
    });
    this.readyItems()
    this.checkLogin();
  }
  readyItems(){
    var resLoad = 0;
    GlobalConstants.pageWidth = this.page?.nativeElement?.offsetWidth;
    this.fn.getTime();
    var si = setInterval(()=>{
      if(!this.page){
        return;
      }
      if(this.page.nativeElement.offsetWidth<769){
        //if(this.fn.getUrlPath3().includes('admin'))return;
        this.rl.setMobileView(true)
        this.isMobile = true;
        this.ds.setSystemType(true)
      }
      else{
        //if(this.fn.getUrlPath3().includes('admin'))return;
        this.rl.setSideMenuWide(true)
        this.rl.setMobileView(false)
        this.isMobile = false;
      }
      if(
        this.fn.getUrlPath3().includes('donate') || this.fn.getUrlPath3().includes('/fund-') || this.fn.getUrlPath3().includes('sponsor') || this.fn.getUrlPath3().includes('sign-')
        || this.fn.getUrlPath3().includes('reset') || this.fn.getUrlPath3().includes('verify') || this.fn.getUrlPath3().includes('profile-')
        || this.fn.getUrlPath3().includes('slider') || this.fn.getUrlPath3().includes('-information')
      ){
        //this.fn.getUrlPath3().includes('admin')
        this.isMenu = true;
        this.isApp = false;
        this.isMobile = false;
        this.isWide = '1';
      }
      else{
        this.isMenu = false;
      }
    },30000)
    var si = setTimeout(()=>{
      if(!this.page){
        return;
      }
      GlobalConstants.pageWidth = this.page.nativeElement.offsetWidth;
      if(this.page.nativeElement.offsetWidth<769){
        //if(this.fn.getUrlPath3().includes('admin'))return;
        this.rl.setSideMenuWide(false)
        this.rl.setMobileView(true)
        this.isMobile = true;
        this.ds.setSystemType(true)
      }
      else{
        //if(this.fn.getUrlPath3().includes('admin'))return;
        this.rl.setSideMenuWide(true)
        this.rl.setMobileView(false)
        this.isMobile = false;
      }
    },1150)
    this.platform.resize.subscribe((data)=>{
      var si = setTimeout(()=>{
        GlobalConstants.pageWidth = this.page.nativeElement.offsetWidth;
        if(this.page.nativeElement.offsetWidth<769){
          this.isMobile = true;
          this.rl.setSideMenuWide(false)
          this.ds.setSystemType(true)
          if(
            this.fn.getUrlPath3().includes('donate') || this.fn.getUrlPath3().includes('/fund-your-future') || this.fn.getUrlPath3().includes('sponsor') || this.fn.getUrlPath3().includes('sign-')
            || this.fn.getUrlPath3().includes('reset') || this.fn.getUrlPath3().includes('verify') || this.fn.getUrlPath3().includes('profile-')
            || this.fn.getUrlPath3().includes('slider') || this.fn.getUrlPath3().includes('-information')
          ){
            //  || this.fn.getUrlPath3().includes('admin')
            this.isMenu = true;
            this.isApp = false;
            this.isMobile = false;
            this.isWide = '1';
          }
        }
        else{
          this.isMobile = false;
          this.rl.setSideMenuWide(true)
          this.ds.setSystemType(false)
        }
      },550)
    })

    //this.setModule();
    this.router.events.subscribe(event => {
      this.currentUrl = this.fn.getUrlPath();
      if (event instanceof NavigationEnd) {
        var screen;
        var val = event.urlAfterRedirects.split('/');
        if(val.indexOf('tabs')>-1){
          screen = val[val.indexOf('tabs')+1]+' tab';
          if(val[val.indexOf('tabs')+2]){
            screen = val[val.indexOf('tabs')+1] + ' ' + val[val.indexOf('tabs')+2]+' tab';
          }
        }
        else{
          val = event.urlAfterRedirects.split('/');
          if(val.length>1){
            screen = val[1];
            if(val[2])
            screen = val[1]+' '+val[2];
          }
        }
        this.ds.getProfileValue().subscribe((data:any)=>{
          this.userData = data;
        })
        this.analytics.setScreenName(screen)
        if(
          this.fn.getUrlPath3().includes('donate') || this.fn.getUrlPath3().includes('/fund-your-future') || this.fn.getUrlPath3().includes('sponsor') || this.fn.getUrlPath3().includes('sign-')
          || this.fn.getUrlPath3().includes('reset') || this.fn.getUrlPath3().includes('verify') || this.fn.getUrlPath3().includes('profile-')
          || this.fn.getUrlPath3().includes('fund')
        ){
          this.isMenu = true;
        }
        else{
          this.isMenu = false;
        }
        this.pageT = this.fn.getIndex(this.fn.getUrlPath3(),GlobalConstants.appPages,'url');
        this.page2 = this.fn.getIndex(this.fn.getUrlPath(),GlobalConstants.appPages,'url');
        if(this.page2?.title)
        this.rl.setActiveMenu(this.page2?.title.toLowerCase())
        this.isSettled++;
        GlobalConstants.history.push([]);
        this.fn.dismissLoader();
        var path = this.fn.getUrlPath3();
        path = path.slice(1);
        var pathList = this.fn.findIndex(path,GlobalConstants.menuArray,'url');
        this.activeMenu = GlobalConstants.menuArray[pathList];
        if(this.activeMenu?.name=='loans'){
          if(this.userData?.user?.role=='1'){
            this.rl.setActiveMenu(this.activeMenu.name)
          }
          else if(this.userData?.user?.role=='2'){
            this.rl.setActiveMenu('lendings')
          }
        }
        else{
          this.rl.setActiveMenu(this.activeMenu?.name)
        }
        if(this.activeMenu?.name){
          this.activeMenu = this.activeMenu.name;
        }
        if(this.fn.getUrlPath().includes('slider')){
        }
        else{
          if(!path || path==''){
            if(GlobalConstants.isLogged){
              if(this.isSettled>1)
                  return;
                this.fn.loadReplace('overview')
            }
              else{
                setTimeout(()=>{
                  if(this.fn.getUrlPath().includes('slider')){
                    return;
                  }
                  else{
                    this.fn.loadReplace('sign-in')
                  }
                },1200)
            }
          }
        }
        this.ds.getResValue().subscribe((data)=>{
          //console.log(data)
          this.resource = data;
          if(data && data.status){
            GlobalConstants.txtMinBal = 'The minimum account opening balance of NGN'+this.resource?.data?.verification_rate+' is required to open your FCMB account. Kindly top up to unlock more possibilities.';
            if(data?.banner && resLoad<1){
              var savedAlert = localStorage.getItem(GlobalConstants.tokenName+'_banner');
              if(savedAlert==data?.banner?.id){
              }
              else{
                this.fn.openModal({
                  component:ViewAlertPage,
                  componentProps:{data:{
                    title:data?.banner?.title,
                    msg:data?.banner?.msg,
                    is_banner:data?.banner?.is_banner,
                    id:data?.banner?.id,
                    image:data?.banner?.image,
                    is_auto:true
                  }},
                  backdropDismiss:false
                },'alertModal')
              }
            }
            //this.resource = data;
            var plat;
            if(this.platform.is('ios')){
              plat = 'ios';
            }
            else if(this.platform.is('android')){
              plat = 'android';
            }
            //else plat = 'android';
            //plat = 'android';
            if(this.platform.is('capacitor')){
              if(plat=='android' || plat=='ios'){
                if(!data.data)
                  return;
                  //console.log(GlobalConstants.appVersion)
                  //console.log(data.data['version_'+plat])
                  //console.log(GlobalConstants.appVersion!=data.data['version_'+plat])
                if(data.data['version_'+plat] && !GlobalConstants.isUpdateClicked && (GlobalConstants.appVersion && (GlobalConstants.appVersion!=data.data['version_'+plat]))){
                  var url;
                  url = data.data[plat];
                  if(url && data.data['version_'+plat] && GlobalConstants.appVersion<data.data['version_'+plat]){
                    if(sessionStorage.getItem('vsAlert'))
                      return;
                    this.prepConfirm('update',url,'update','','A new version is available. Please download a new version to enjoy amazing new features');
                    sessionStorage.setItem('vsAlert','1');
                  }
                }
              }
            }
            resLoad++;
          }
        })
        this.ds.getValue().subscribe((data)=>{
          if(data){
            if(this.notify)
            return;
            if(!this.fn.getUrlPath().includes('profile-infor') && !this.fn.getUrlPath().includes('donate') && !this.fn.getUrlPath().includes('/fund-your-future') && !this.fn.getUrlPath().includes('welcome')){
              this.ds.getCandidateValue().subscribe((data:any)=>{
                this.notify = true;
                this.student = data;
                if(this.userData?.candidate && this.student?.id && !this.student?.tutionfee && !this.fn.getUrlPath3().includes('welcome') && !this.fn.getUrlPath3().includes('bill') && !this.fn.getUrlPath3().includes('contact') && !this.fn.getUrlPath3().includes('alert') && !this.fn.getUrlPath3().includes('job')){
                  if(this.fn.getUrlPath().includes('verification') || this.fn.getUrlPath().includes('sign') || this.fn.getUrlPath().includes('reset') || this.fn.getUrlPath().includes('recover') || this.fn.getUrlPath().includes('profile-info'))
                  return;
                  this.fn.presentToast('Please provide your tuition fee to continue. You will find it under school information tab in your profile',10000,'danger')
                  this.fn.loadPath('profile-information')
                }
                else if(this.userData?.candidate && this.student?.id && this.student?.is_verified!=='1' && !this.fn.getUrlPath3().includes('welcome') && !this.fn.getUrlPath3().includes('bill') && !this.fn.getUrlPath3().includes('contact') && !this.fn.getUrlPath3().includes('alert') && !this.fn.getUrlPath3().includes('job') && !this.fn.getUrlPath3().includes('dona') && !this.fn.getUrlPath3().includes('top-up')){
                  if(this.fn.getUrlPath().includes('verification') || this.fn.getUrlPath().includes('sign') || this.fn.getUrlPath().includes('reset') || this.fn.getUrlPath().includes('recover') || this.fn.getUrlPath().includes('profile-info'))
                  return;
                  //this.prepConfirm('verify','','','','Your account has not been verified. With a verified account you can receive public donations towards your tuition and access more opportunities. To proceed, you will now be redirected to verification page.');
                  if(data && (data?.is_verified!='1' && !data?.istopped)){
                    this.prepConfirm('verify_top_up','','','',GlobalConstants.txtMinBal);
                    return;
                  }
                }
                else if(this.userData?.candidate && this.student?.id && !this.student?.story && !this.fn.getUrlPath3().includes('welcome') && !this.fn.getUrlPath3().includes('bill') && !this.fn.getUrlPath3().includes('contact') && !this.fn.getUrlPath3().includes('alert') && !this.fn.getUrlPath3().includes('job')){
                  if(this.fn.getUrlPath().includes('verification') || this.fn.getUrlPath().includes('sign') || this.fn.getUrlPath().includes('reset') || this.fn.getUrlPath().includes('recover') || this.fn.getUrlPath().includes('profile-info'))
                  return;
                  this.prepConfirm('story','','','','You do not have any story to tell the public to encourage them to sponsor your education. Telling a story increases your chances of getting sponsorships and donations towards your tuition','Tell Your Story!');
                }
              })
            }
            setTimeout(()=>{
              this.notify = false;
            },10000)
          }
        })
        this.ds.getProfileValue().subscribe((data:any)=>{
          this.userData = data
          if(this.userData?.user && !this.userData?.candidate){
            if(this.fn.getUrlPath3().includes('donation')){
              this.fn.loadAlert('Only students can access this feature');
              this.fn.loadReplace('welcome')
            }
          }
        })
      }
    });
    if(this.platform.is('capacitor')){
      this.OneSignalInit();
      GlobalConstants.isCordova = true;
      this.fn.subscribe(true);
      StatusBar.setOverlaysWebView({ overlay: false });
      StatusBar.setBackgroundColor({
        color:'#142458'//'#2c3a69'//'#2c3a69'
      })
      SplashScreen.show({
        showDuration: 5000,
        autoHide: true,
      });
      this.pageT = this.fn.getIndex(this.fn.getUrlPath(),GlobalConstants.appPages,'url');
      //this.statusBar.styleDefault();
      this.platform.backButton.subscribeWithPriority(0, () => {
        var pathArr = this.router.url.split('dashboard');
        var pathArr2 = this.router.url.split('slider');
        var pathArr3 = this.router.url.split('welcome');
        if(pathArr.length>1 || pathArr2.length>1 || pathArr3.length>1){
          this.exitAlert();
        }
        else{
          this.fn.goBack();
        }
      });
      this.fn.getAppVersion();
      this.checkSlider();
      this.ds.setSystemType(false)
    }
    else{
      this.ds.setSystemType(false)
      //this.checkSlider();
      this.setOneSignal();
    }
    if(GlobalConstants.isFrontTesting){
      this.ds.setProfile({
        full_name:'Mary Shadrack',
        email:'mary@gmail.com',
        phone:'07054898989',
        address:'28 Adekunle Ajasin Lagos',
        is_verified:true,
      })
    }
    setInterval(()=>{
      this.ds.getPlayerID();
    },60000)
  }
  async getDeviceState(){
    //const dState = await OneSignal.getDeviceState();
    //console.log('component did mount devicestate:'+dState)
    //console.log('component did mount devicestate is Subscribed:'+dState.isSubscribed)
    //console.log('component did mount devicestate user id:'+dState.userId)
  }
  state = true;
  // Call this function when your app starts
  OneSignalInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE  
    // OneSignal.setLogLevel(6, 0);

    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId("b96d3bc6-8a66-43a3-8ef6-21b58729651a");
    OneSignal.addSubscriptionObserver(function (state:any) {
      if (!state.from.subscribed && state.to.subscribed) {
        // get player ID
        GlobalConstants.playerID = state.to.userId;
      }
      GlobalConstants.hasPush = state.to.subscribed
      //console.log("Push Subscription state changed: " + JSON.stringify(state));
      if(state && state.to)
        GlobalConstants.playerID = state.to.userId;
      console.log("Player ID: " + GlobalConstants.playerID);
    });
    var setID = setInterval(()=>{
      if(GlobalConstants.playerID){
        this.storage.set(GlobalConstants.token+'_player_id',GlobalConstants.playerID)
        //console.log(GlobalConstants.playerID)
        clearInterval(setID)
      }
    })
    this.storage.get(GlobalConstants.token+'_player_id').then((data)=>{
      //console.log(data)
      if(data){
        GlobalConstants.playerID = data;
      }
      else{
        var ci = setInterval(()=>{
          console.log(data)
          if(GlobalConstants.playerID){
            clearInterval(ci)
          }
          this.state = !this.state;
          //console.log(this.state)
          OneSignal.disablePush(this.state);
          //console.log('Player ID is: '+GlobalConstants.playerID)
          if(GlobalConstants.playerID && !GlobalConstants.hasPush){
            OneSignal.disablePush(false);
          }
        },5000)
      }
    })
    OneSignal.setNotificationOpenedHandler((data:any) =>{
      //let msg = data.notification.payload?data.notification.payload.body:'';
      //let title = data.notification.payload?data.notification.payload.title:'';
      let msg = data.notification.notificationId?data.notification.body:'';
      let title = data.notification.notificationId?data.notification.title:'';
      if(msg){
        //this.fnS.loadAlert(msg,title);
        this.fn.openModal({
          component:ViewAlertPage,
          componentProps:{data:{title:title,msg:msg}}
        },'view_alert')
      }
    });

    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      this.fnS.presentToast("User accepted notifications: " + accepted);
    });
  }
  setOneSignal():void{
    var OneSignal = window[OneSignal] || [];
    var OneSignal = window['OneSignal'] || [];
    console.log("Init OneSignal");
    OneSignal.push(["init", {
      appId: "b96d3bc6-8a66-43a3-8ef6-21b58729651a",
      autoRegister: false,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: false
      }
    }]);
    console.log('OneSignal Initialized');
    OneSignal.push(function () {
      console.log('Register For Push');
      OneSignal.push(["registerForPushNotifications"])
    });
    OneSignal.push(function () {
      OneSignal.getUserId().then(function (userId) {
        GlobalConstants.playerID = userId;
        //console.log("User ID is", userId);
      });
      // Occurs when the user's subscription changes to a new value.
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        //console.log("The user's subscription state is now:", isSubscribed);
        OneSignal.getUserId().then(function (userId) {
          GlobalConstants.playerID = userId;
          //console.log("User ID is", userId);
        });
      });
    });
  }
  checkLogin(){
    this.ds.getProfileValue().subscribe((data:any)=>{
      this.userData = data;
      if(data?.user?.group?.name=='owner' || data?.user?.group?.name=='admin'){
        GlobalConstants.isAdmin = true;
        GlobalConstants.isSuperAdmin = true;
      }
      if(data.id){
        if(this.loadCandidate>0)
          return;
        this.loadCandidate++;
        //this.ds.getCandidateData(data.id);
      }
      if(this.userData?.user?.role=="2"){
        this.menus = GlobalConstants.lenderArray;
      }
      else if(this.isAdmin || this.userData?.admin || this.userData?.user?.role_name?.includes('agent')){
        this.menus = GlobalConstants.uniArray;
        if(GlobalConstants.isAdmin){
          this.uniArray = GlobalConstants.adminArray;
        }
        else if(this.userData?.user?.role>2){
          if(this.userData?.user?.role==4){
            this.uniArray = GlobalConstants.agentArray;
          }
        }
        else if(this.userData?.admin){
          if(this.userData?.user?.group?.name.includes('bank'))
            this.uniArray = GlobalConstants.bankArray;
          //console.log(this.uniArray)
        }
        this.menus = this.uniArray;
        var i = 0;
        setTimeout(()=>{
          this.ds.getSystemType().subscribe((data)=>{
            this.isApp = data;
          })
          this.menus.forEach(element => {
            if(!this.isApp){
              if(element?.name?.length>20){
                this.menus[i].name = this.menus[i].name[0]+this.menus[i].name[1]+this.menus[i].name[2]+this.menus[i].name[3]+this.menus[i].name[4]+this.menus[i].name[5]+this.menus[i].name[6]+this.menus[i].name[7]+this.menus[i].name[8]+this.menus[i].name[9]+this.menus[i].name[10]+this.menus[i].name[11]+this.menus[i].name[12]+this.menus[i].name[13]+this.menus[i].name[14]+this.menus[i].name[15]+'..';
              }
            }
            else{
              if(element?.name?.length>5){
                this.menus[i].name = this.menus[i].name[0]+this.menus[i].name[1]+this.menus[i].name[2]+this.menus[i].name[3]+'..';
              }
            }
            i++;
          });
        },2000)
      }
      if(this.menus.length>8){
        //this.menus = this.menus.splice(0,7);
      }
      //console.log(this.menus)
    })
    var i = setInterval(()=>{
      clearInterval(i)
      this.ds.getValue().subscribe((data)=>{
        //&& GlobalConstants.pageWidth>0
        if(data && GlobalConstants.token){
          clearInterval(i)
          //this.ds.getProfileData(true);
          //get countries and state
          this.ds.getStateCall();
          this.ds.getCountryCall();
          this.ds.getLGACall();
          if(this.fn.getUrlPath().includes('profile-'))
          this.ds.getInstitutionsCall(true);
          this.ds.getResourceFn(true);
          if(this.fn.getUrlPath().includes('admin')){
            this.ds.getFacultiesCall();
            this.ds.getDeptsCall();
          }
        }
      })
    })
    if(GlobalConstants.flushTesting){
      this.ds.setValue(false);
      this.storage.remove(GlobalConstants.tokenName).then(()=>{
      })
    }
    else{
      this.storage.get(GlobalConstants.tokenName).then((data)=>{
        if(data){
          GlobalConstants.token = data;
          this.ds.setValue(true)
          this.ds.getProfileData(true);
        }
        else{
          this.ds.setValue(false)
        }
      })
    }
  }
  checkSlider(){
    this.storage.get('isSlider').then((data)=>{
      if(!data){
        this.doSlider();
      }
      else{
        this.fn.log('slider available');
      }
    })
  }
  doSlider(){
    this.fn.loadPath('slider');
  }
  async exitAlert() {
    const toastOption = await this.toastCtrl.create({
      header: 'Confirm Exit',
      message: 'You are about to exit app',
      position: 'bottom',
      color: 'danger',
      duration:5000,
      buttons: [
        {
          side: 'end',
          text: 'Exit',
          handler: () => {
            navigator['app'].exitApp();
          }
        },
        {
          side: 'end',
          text: 'Stay',
        }
      ]
    });
    toastOption.present();
  }
  openMenu(name,url?){
    this.activeMenu = '';
    this.activeMenu = name;
    this.fn.loadPath(url)
  }
  async prepConfirm(term,item,action,type,txt?,header?){
    var text;
    if(txt){
      text = txt;
    }
    else{
      text = 'Do you want to proceed with '+action;
    }
    const alert = await this.alertCtrl.create({
      header: header?header:'Attention!',
      message: text,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          text: 'Ok',
          role: 'okay',
          cssClass: 'secondary',
          handler: () => {
            if(term=='view_post'){
              sessionStorage.setItem('vp','1');
              this.fn.loadPath(item)
            }
            else if(term=='update'){
              if(!item) return;
              GlobalConstants.isUpdateClicked = true;
              this.fn.openUrl(item,'_blank');
            }
            else if(term=='verify'){
              this.fn.loadPath('request-verification')
            }
            else if(term=='verify_top_up'){
              this.fn.loadPath('top-up')
            }
            else if(term=='story'){
              this.openModal({
                component:StoryPage,
                backdropDismiss:false
              })
            }
          }
        }
      ]
    });
    await alert.present();
  }
  mouse(event){
    this.isOnCount = 0;
    this.isOn = true;
    if(this.platform.is('android') || this.platform.is('ios')){
      if(this.doReload && !this.fn.getUrlPath().includes('admin')){
        //if(confirm('App has been idle for a while and wants to refresh view.')){window.location.reload();}
        //else{this.isOn = true;this.isOnCount = 0;this.doReload = false;}
      }
    }
  }
  key(event){
    this.isOnCount = 0;
    this.isOn = true;
    if(this.platform.is('android') || this.platform.is('ios')){
      if(this.doReload && !this.fn.getUrlPath().includes('admin')){
        //if(confirm('App has been idle for a while and wants to refresh view.')){window.location.reload();}
        //else{this.isOn = true;this.isOnCount = 0;this.doReload = false;}
      }
    }
  }
  si;
  doStart(){
    this.si = setInterval(()=>{
      this.isOn = false;
    },5000)
  }
  loadSessions(){
    var year = new Date().getFullYear();
    var nextyear = year+1;
    var lastyear = year-1;
    var prevyear = year-2;
    this.sessions.push({'name':(prevyear-1)+'/'+(lastyear-1)})
    this.sessions.push({'name':prevyear+'/'+lastyear})
    this.sessions.push({'name':lastyear+'/'+year})
    this.sessions.push({'name':year+'/'+nextyear})
    this.sessions.push({'name':nextyear+'/'+(nextyear+1)})
    GlobalConstants.sessions = this.sessions;
  }
  async openModal(data){
    const modal = await this.modalCtrl.create(data);
    modal.onDidDismiss().then((data)=>{
      //console.log(data)
      if(data?.data){
        this.ds.getCandidateData(this.userData?.user?.id,true);
      }
    })
    await modal.present();
  }
  info:StatusBarInfo;
  keys:string[] = [];
  backgroundColor:string = "";
  changeBackgroundColor(){
    return;
    if(window.confirm('do you want to change background color'))
    this.backgroundColor = window.prompt('enter your color code')
    var options:BackgroundColorOptions={
      color:this.backgroundColor
    }
    StatusBar.setBackgroundColor(options).then((data)=>{
      alert('Status bar color changed to: '+this.backgroundColor)
      this.getStatusBarInformation();
    })
  }
  getStatusBarInformation(){
    StatusBar.getInfo().then((info:StatusBarInfo)=>{
      this.info = info;
      this.keys = Object.keys(this.info);
    })
  }
}