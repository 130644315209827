import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data-service.service';
import { FunctionServiceService } from 'src/app/services/function-service.service';
import { ReloadServiceService } from 'src/app/services/reload-service.service';

@Component({
  selector: 'app-in-house-scholarships',
  templateUrl: './in-house-scholarships.page.html',
  styleUrls: ['./in-house-scholarships.page.scss'],
})
export class InHouseScholarshipsPage implements OnInit {
  userData;isLoading;resource;eduRecords;courses
  constructor(
    private ds:DataService,
    private fn:FunctionServiceService,
  ) { }

  ngOnInit() {
    this.ds.getCoursesCall();
    this.ds.getCourse().subscribe((data:any)=>{
      this.courses = data;
    })
    this.ds.getProfileValue().subscribe((data)=>{
      this.userData = data;
      if(data){
        var edu = sessionStorage.getItem('edu_'+this.userData?.user?.id)
        if(edu){
          this.eduRecords = JSON.parse(edu);
        }
        else{
          if(!this.userData?.user?.id)
            return;
          this.ds.getAcademicRecord(this.userData?.user?.id).subscribe((data:any)=>{
            this.eduRecords = data?.data?.items[0]
            if(this.eduRecords){
              sessionStorage.setItem('edu_'+this.userData?.user?.id,JSON.stringify(this.eduRecords))
            }
          });
        }
      }
    })
    this.ds.getResValue().subscribe((data:any)=>{
      this.resource = data;
    })
  }
  apply(){
    this.isLoading = true;
    this.ds.applyScholarship({id:this.userData?.user?.id,type:'inhouse'}).subscribe((data:any)=>{
      this.isLoading = false;
      if(data.status){
        this.fn.loadAlert(data.message,'Thank you, all Done!');
        this.closeModal();
      }
      else{
        this.fn.loadAlert(data.message,'Eh yah, sorry!')
      }
    },error=>{
      this.isLoading = false;
      this.fn.loadError(error);
    })
  }
  closeModal(){
    this.fn.closeModal();
    if(this.fn.getUrlPath3().includes('apply-for-scholarship')){
      this.fn.goBack();
    }
  }

}
