
import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { GlobalConstants } from 'src/app/common/global-constants';
import { DataService } from 'src/app/services/data-service.service';
import { FunctionServiceService } from 'src/app/services/function-service.service';

@Component({
  selector: 'app-view-alert',
  templateUrl: './view-alert.page.html',
  styleUrls: ['./view-alert.page.scss'],
})
export class ViewAlertPage implements OnInit {
  data;key = GlobalConstants.tokenName+'_alerts_view';keys:any = []
  constructor(
    private fn:FunctionServiceService,
    private ds:DataService,
    private storage:Storage
  ) { }
  ngOnInit() {
    if(this.data?.id){
      this.storage.get(this.key).then((data)=>{
        if(data){
          this.keys = data;
          //check if id is already added
          if(this.keys.indexOf(this.data?.id)>-1){
            //already exist
          }
          else{
            this.keys.push(this.data.id)
          }
        }
        else{
          this.keys.push(this.data.id)
        }
      })
    }
  }
  closeModal(force?){
    if(this.data?.id){
      this.storage.set(this.key,this.keys).then((data)=>{
        //console.log('Data saved: '+JSON.stringify(data))
      })
      if(force && this.data?.is_banner && this.data?.is_banner!=null && this.data?.is_banner!='0'){
        localStorage.setItem(GlobalConstants.tokenName+'_banner',this.data?.id)
      }
    }
    this.fn.closeModal();
  }

}
