import { Injectable, NgModule} from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {Storage} from '@ionic/storage';
import { GlobalConstants } from './global-constants';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { FunctionServiceService } from '../services/function-service.service';
import { DataService } from '../services/data-service.service';
var params = {}; var searchArr = [];
var pipe = new DatePipe('en-US');var currentUrl;
var searchTerm,currentLink;
@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
constructor(
    private storage:Storage,private fn:FunctionServiceService,private platform:Platform,
    private ds:DataService
    ){
    //GlobalConstants.token = localStorage[GlobalConstants.tokenName];
    //this.fn.log(sessionStorage.getItem(GlobalConstants.companyKey));
}
intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log(req.url)
    //console.log(currentLink)
    if(!navigator.onLine && req.url.includes('https')){
        if(req.url.includes('Profile') || req.url.includes('profile')){
            this.storage.get(GlobalConstants.tokenName+'_profile').then((data)=>{
                if(data)
                  this.ds.setProfile(data)
              })
              return of(new HttpResponse({ status: 500, body: {message:GlobalConstants.txtNetwork} }));
        }
        else{
            this.fn.presentToast(GlobalConstants.txtNetwork);
            return of(new HttpResponse({ status: 500, body: {message:GlobalConstants.txtNetwork} }));
        }
    }
    if(this.fn.getUrlPath().includes('sign-in') || this.fn.getUrlPath().includes('sign-up') || this.fn.getUrlPath().includes('recover')){}
    else{
        if(GlobalConstants.pageWidth<1){
            //return;
        }
    }
    if(currentLink==req.url){
        currentLink = '';
        return of(new HttpResponse({ status: 500, body: {} }));
    }
    currentLink = req.url;
    //console.log(currentUrl)
    //console.log(req.url)
    if(!(req.url.includes('search')) && currentUrl==req.url)
        return;
    currentUrl = req.url;
    if(!navigator.onLine){
        if(this.platform.is('capacitor')){
            if(req.url.includes('profile')){
                return;
            }
        }
    }
    if((!navigator.onLine && !GlobalConstants.isTesting) && !this.fn.getUrlPath().includes('localhost')){
        if(req.url.includes('profile')){
            return;
        }
        else
        this.fn.presentToast(GlobalConstants.txtNetwork);
    }
    if(req.url.includes('search')){
        var index = Object.keys(req.body).indexOf('term');
        //this.fn.log(req.body['term'].length)
        if(GlobalConstants.searchArr){
            searchArr = [];
        }
        if(req.body['term']){
            //console.log(searchTerm)
            //console.log(GlobalConstants.isSelected)
            if(searchTerm==req.body['term'])
              return;
            searchTerm = req.body['term'];
            //console.log(req.body['term'])
            if(GlobalConstants.isSelected)
            return;
        }
        ///^[a-zA-Z]+$/.test(str);
        //this.fn.log(/^[a-zA-Z]+$/.test(req.body['term']))
        //this.fn.log(req.body['term'].split(" ").length)
        if(req.body['term'] && (((/^[a-zA-Z]+$/.test(req.body['term']) || req.body['term'].split(" ").length>0) && req.body['term'].length>8) || req.body['term'].length>25 || req.body['term'].split('(').length>1)){
            //return;
        }
        if(searchArr.length>0){
            if(searchArr.indexOf(req.body['term'])>=0 || ((/^[a-zA-Z]+$/.test(req.body['term']) || req.body['term'].split(" ").length>0) && req.body['term'].length>8) || (req.body['term'].length>25)){
                //return;
            }
            else{
                searchArr.push(req.body['term']);
            }
        }
        else{
            searchArr.push(req.body['term']);
        }
    }
    else{
        if(!req.url.includes('no-string') && !req.url.includes('profile') && !req.url.includes('bill-screen') && (!GlobalConstants.isLoading || !GlobalConstants.isLoader)){
            GlobalConstants.isLoader = true;
            //this.fn.presentLoading('','while I connect to server');
        }
        if(req.responseType=='blob' || req.responseType=='json' || req.responseType=='arraybuffer' || req.responseType=='text'){
            this.fn.dismissLoader();
            GlobalConstants.isLoader = false;
            currentUrl = '';
        }
    }
    params = {};
    var body = req.body;
    if(!body)
        body = {};
    if(req.body){
        //find index of term
        var index = Object.keys(req.body).indexOf('term');
        if(index>=0 && (req.body.term==null || !req.body.term)){
            //this.fn.presentToast('Term cannot be empty');
            return;
        }
    }
    /*var post = req.method;
    if(req.method=='OPTIONS'){
        post = 'POST';
        this.fn.log('option')
    }*/
    //this.fn.log(req.method);
    if(!body['setting_id']){
        if(req.url.includes('search_product')){
            if(Object.keys(body).length>0){
                if(sessionStorage.getItem(GlobalConstants.companyKey))
                body['setting_id'] = sessionStorage.getItem(GlobalConstants.companyKey);
            }
            else{
                if(sessionStorage.getItem(GlobalConstants.companyKey))
                body['setting_id'] = sessionStorage.getItem(GlobalConstants.companyKey);
            }
        }
        else if(req.url.includes('product') || req.url.includes('sales') || req.url.includes('recoverAccount')){
        }
        else{
            if(Object.keys(body).length>0){
                if(sessionStorage.getItem(GlobalConstants.companyKey))
                body['setting_id'] = sessionStorage.getItem(GlobalConstants.companyKey);
            }
            else{
                if(sessionStorage.getItem(GlobalConstants.companyKey))
                body['setting_id'] = sessionStorage.getItem(GlobalConstants.companyKey);
            }
        }
    }
    /*if(body['date']){
        var date = new Date(body['date']);
        body['date'] = pipe.transform(date,'dd/MM/yyyy');
        this.fn.log(body['date']);
    }*/
    const reqCopy = req.clone({body});
    if(GlobalConstants.token){
        const dupReq = req.clone({ 
            headers: req.headers.set('Auth', 'Bearer '+GlobalConstants.token).set('version',GlobalConstants.appVersion),
            body: body,
            //method:post
         });
         var done = false;
         var start = true;
         var count = 0;
         const started = Date.now();
         var st = setInterval(()=>{
            count++;
            //console.log(GlobalConstants.resource)
             if(start && !done){
                if(req.url.includes('profile')){
                    return;
                }
                if(GlobalConstants.resource && GlobalConstants.resource.settings && GlobalConstants.resource.settings.setting_id){
                    //if(GlobalConstants.resource.clist.indexOf(GlobalConstants.resource.settings.setting_id)<=-1)
                        //this.fn.presentToast('Hang on, still processing server request. Just a little more patience, I will get the results for you!','','danger');
                }
             }
             else{
                searchTerm = '';
                currentLink = '';
                 start = false;
                 clearInterval(st);
             }
             if(count>10){
                searchTerm = '';
                currentLink = '';
                start = false;
                clearInterval(st);
             }
         },10000)
         return next.handle(dupReq).pipe(
             finalize(()=>{
                 const elapsed = Date.now() - started;
                 this.fn.log(`URL ${req.url} took ${elapsed}ms`)
                 this.fn.dismissLoader();
                 done = true;
                 currentUrl = '';
                searchTerm = '';
                currentLink = '';
             }

             )
         );
    }
    else{
        const dupReq = req.clone({ 
            headers: req.headers.set('version',GlobalConstants.appVersion),
            body: body,
            //method:post
         });
         var done = false;
         var start = true;
         var count = 0;
         const started = Date.now();
         var st2 = setInterval(()=>{
             count++;
             if(start && !done){
                if(req.url.includes('profile')){
                    return;
                }
                if(GlobalConstants.resource && GlobalConstants.resource.settings && GlobalConstants.resource.settings.setting_id){
                    //if(GlobalConstants.resource.clist.indexOf(GlobalConstants.resource.settings.setting_id)<=-1)
                        //this.fn.presentToast('Hang on, still processing server request. Just a little more patience, I will get the results for you!','','danger');
                }
             }
             else{
                searchTerm = '';                 
                currentLink = '';
                start = false;
                clearInterval(st2);
             }
             if(count>10){
                searchTerm = '';
                currentLink = '';
                start = false;
                clearInterval(st);
             }
         },10000)
         return next.handle(dupReq).pipe(
             finalize(()=>{
                 const elapsed = Date.now() - started;
                 this.fn.log(`URL ${req.url} took ${elapsed}ms`)
                 this.fn.dismissLoader();
                 done = true;
                 currentUrl = '';
                 searchTerm = '';
                 currentLink = '';
             }

             )
         );
    }
    }
};
@NgModule({
providers: [
{ provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true }
]
})
export class InterceptorModule { 
}