import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';


//const { FirebaseAnalytics, Device } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analyticsEnabled:boolean = true;

  constructor(
    private platform:Platform,
    private router:Router
  ) {
    this.initFb()
    /*this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
    ).subscribe((e: RouterEvent) => {
      console.log('route changed: ', e.url);
      this.setScreenName(e.url)
    });*/
  }
  async initFb() {
    if ((await Device.getInfo()).platform == 'web') {
      await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }
  async setUser(id) {
    // Use Firebase Auth uid
    await FirebaseAnalytics.setUserId({
      userId: id,
    });
  }
  async setProperty(name,value) {
    await FirebaseAnalytics.setUserProperty({
      name: name,//"framework",
      value: value,//"angular",
    });
  }
  async logEvent(name,method) {
    await FirebaseAnalytics.logEvent({
      name: name,//"login",
      params: {
        method: method,//"email"
      }
    });
  }
  async setScreenName(screenName) {
    //console.log(screenName)
    await FirebaseAnalytics.setScreenName({
      screenName
    });
  }
  async toggleAnalytics() {
    this.analyticsEnabled = !this.analyticsEnabled;
    await FirebaseAnalytics.setCollectionEnabled({
      enabled: this.analyticsEnabled,
    });    
  }
}
