// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  flutter:'FLWPUBK-e5df7ccf2b125e82441be53ee43ea817-X',
  //FLWPUBK_TEST-fb96138a4e5def2321c2e67eea7096d1-X FLWPUBK-e5df7ccf2b125e82441be53ee43ea817-X
  flutterTest:'FLWPUBK-e5df7ccf2b125e82441be53ee43ea817-X',
  firebaseConfig : {
      apiKey: "AIzaSyCWTY3EobmSMNRZH3ou83XU_4OTnZw9P6Q",
      authDomain: "presspayng-31ca8.firebaseapp.com",
      projectId: "presspayng-31ca8",
      storageBucket: "presspayng-31ca8.appspot.com",
      messagingSenderId: "19176270706",
      appId: "1:19176270706:web:4679c31edc5aa823cecbeb",
      measurementId: "G-WTP7P2C1Z8"
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
