import { Observable } from 'rxjs';

export class GlobalConstants{
    public static isFrontTesting:boolean = false;
    public static flushTesting:boolean = false;
    public static appUrl: string = 'https://studentloan.presspay.ng/';
    public static mainUrl: string = 'presspay.ng';
    public static isTesting: boolean = false;
    //public static baseUrl: string = 'http://localhost:8000/';
    // public static baseUrl: string = 'http://localhost/studentloan/api/';
    public static baseUrl: string = 'https://presspay.ng/studentloan/';
    public static appTitle: string = 'Brief Demo';
    public static pageWidth:number = 0;
    public static time: string = '';
    public static isDown: boolean = false;
    public static isUpdateClicked: boolean = false;
    public static registerAdded: boolean = false;
    public static serverProfile: boolean = false;
    public static isOpened: boolean = false;
    public static isSelected: boolean = false;
    public static isStopped: boolean = false;
    public static curLink: string = 'home';
    public static testBarcode:string = 'NB/CE/0712';
    public static asset:string = 'laravel/assets/uploads/logos/';
    public static items = [];
    public static alert = [];
    public static disableText = '';
    public static alertMessage = '';
    public static alertType = '';
    public static disableBtnText = '';
    public static siteUrl: string = 'https://presspay.ng/';
    public static maritalStatus = [
      'single','married','widowed','divorced'
    ];
    public static sessions:any = [];
    public static stages:any = [
      {
        id:'personal_id',
        name:'Personal Information',
        icon:'person',
      },
      {
        id:'id',
        name:'Identification Documents',
        icon:'document-attach',
      },
      {
        id:'addr',
        name:'Contact Address',
        icon:'home',
      },
      {
        id:'edu',
        name:'School Information',
        icon:'storefront',
      },
      {
        id:'bank',
        name:'Bank Information',
        icon:'storefront',
      },
    ];
    public static stagesLoans:any = [
      {
        id:'personal_id',
        name:'Personal Information',
        icon:'person',
      },
      {
        id:'id',
        name:'Identification Documents',
        icon:'document-attach',
      },
      {
        id:'addr',
        name:'Contact Address',
        icon:'home',
      },
      {
        id:'edu',
        name:'School Information',
        icon:'storefront',
      },
      {
        id:'bank',
        name:'Bank Information',
        icon:'storefront',
      },
      {
        id:'refs',
        name:'Referees & Guarantors',
        icon:'people',
      },
    ]
    public static digi: any[] = [];
    public static quiz: any[] = [];
    public static reportQuiz: any[] = [];
    public static lgas: any[] = [];
    public static phones: any[] = [];
    public static faqs: any[] = [];
    public static stats: any[] = [];
    public static allData;
    public static newsID: string;
    public static title: any;
    public static itemsPerPage: number = 15;
    public static gateway: number = 0;
    public static total: number = 0;
    public static appName: string = 'PressPayNg';
    public static pkTest:string = '';
    public static moduleProduction: any = [];
    public static isLoading: boolean = false;
    public static logoSet:boolean = false;
    public static isLoader: boolean = false;
    public static isStart: boolean = false;
    public static txtDate: string = '';
    public static txtMinBal = '';
    public static txtNetwork: string = 'You are not connected to the internet.';
    public static txtError: string = 'An error occured while connecting to server. Please try again!';
    public static txtEmpty: string = 'Please complete field to proceed.';
    public static txt404: string = 'No record found.';
    public static txtLoginReq: string = 'You need to be logged in to perform action!';
    public static txtDenied: string = 'Access denied for user level. Only users with higher privilege can perform action!';
    public static txtSearch: string = 'Please choose at least one search parameter to proceed';
    public static txtExpired: string = 'Your license has expired already. Please renew now!';
    public static txtModuleDeniedCompany: string = 'Your company does not have licence to view this module!';
    public static txtPackageDenied: string = 'You do not have right to view this module. Please upgrade your package!';
    public static txtModuleDeniedUser: string = 'You do not have right to view this module!';
    public static txtComingSoon: string = 'App feature is on its way. It will be live soon!';
    public static dateStr = new Date().getFullYear()+''+(new Date().getMonth())+''+new Date().getDay();
    public static dateStr2 = new Date().getFullYear()+'/'+(new Date().getMonth()+1)+'/'+new Date().getDate();
    public static lat: number;
    public static lon: number;
    public static playerID: string;
    public static device: any = {};
    public static isLogged: boolean = false;
    public static isLoggedAdmin: boolean = false;
    public static isAdmin: boolean = false;
    public static isSuperAdmin: boolean = false;
    public static search: any[];
    public static history: any = [];
    public static resource: any;
    public static profile: any;
    public static profileData: any;
    public static token: string;
    public static tokenAdmin: string;
    public static tokenName: string = 'presspayToken';
    public static tokenNameAdmin: string = 'presspayTokenToken';
    public static companyKey: string = 'compID';
    public static searchParams: any;
    public static tags: any;
    public static isDiaspora: boolean = false;
    public static isCordova: boolean;
    public static hasPush: boolean;
    public static appInfo: any;
    public static appVersion: any = '4.0';
    public resource: Observable<any>;
    public static pageName: any;
    public static module: any = {};
    public static companyID: string;
    public static totalProducts: number = 0;
    public static searchArr: any = false;
    public static searchTerm: any;
    public static activeMenu: any;
    public static appPages = [
        {
          title:'Request Verification',
          url:'/request-verification'
        },
        {
          title:'Living Expense Requests',
          url:'/admin-living-expense-requests'
        },
        {
          title:'Recurring Savings',
          url:'/admin-recurring-savings'
        },
        {
          title:'Welcome',
          url:'/welcome'
        },
        {
          title:'Bills',
          url:'/bills'
        },
        {
          title:'Wallet Histories',
          url:'/admin-wallet-history'
        },
        {
          title:'Pay My Tuition Requests',
          url:'/admin-pay-request'
        },
        {
          title:'Scholarship Applications',
          url:'/admin-scholarships'
        },
        {
          title:'App Onboarders',
          url:'/admin-customers'
        },
        {
          title:'FCMB Accounts',
          url:'/admin-fcmb'
        },
        {
          title:'FCMB Attempts',
          url:'/admin-fcmb-attempts'
        },
        {
          title:'Fund Your Future',
          url:'/admin-fund-your-future'
        },
        {
          title:'Monthly Allocations',
          url:'/monthly-allocations',
        },
        {
          title:'Skill & Job Profiling',
          url:'/skill-job-profile',
        },
        {
          title: 'Home',
          url: '/home',
        },
        {
          title: 'Sign In',
          url: '/sign-in',
        },
        {
          title: 'Lender Information',
          url: '/lender-information',
        },
        {
          title: 'Profile Information',
          url: '/profile-information',
        },
        {
          title: 'Verify Account',
          url: '/verify-account',
        },
        {
          title: 'Recover Account',
          url: '/reset-account',
        },
        {
          title: 'Overview',
          url: '/overview',
        },
        {
          title: 'Loans',
          url: '/loans',
        },
        {
          title: 'Wallet',
          url: '/savings',
        },
        {
          title: 'Loan Re-Payments',
          url: '/payments',
        },
        {
          title: 'Settings',
          url: '/settings',
        },
        {
          title: 'Top Up',
          url: '/top-up',
        },
        {
          title: 'Transfer',
          url: '/wallet-transfer',
        },
        {
          title: 'Profile Management',
          url: '/profile-information',
        },
        {
          title: 'Students',
          url: '/admin-students',
        },
        {
          title: 'Loans',
          url: '/admin-loans',
        },
        {
          title: 'Loan Attempts',
          url: '/admin-loan-attempts',
        },
        {
          title: 'Agents',
          url: '/admin-agents',
        },
        {
          title: 'Departments',
          url: '/admin-depts',
        },
        {
          title: 'Faculties',
          url: '/admin-faculties',
        },
        {
          title: 'Institutions',
          url: '/admin-institutions',
        },
        {
          title: 'Verification Requests',
          url: '/admin-vrs',
        },
        {
          title: 'Lenders',
          url: '/admin-lenders',
        },
        {
          title: 'Users',
          url: '/admin-users',
        },
        {
          title: 'User groups',
          url: '/admin-groups',
        },
        {
          title: 'Wallets',
          url: '/admin-wallets',
        },
        {
          title: 'Academic Records',
          url: '/admin-academic-records',
        },
        {
          title:'Academic Records',
          url:'/academic-records'
        },
        {
          title:'Tuition Payment Requests',
          url:'/tuition-requests'
        },
        {
          title:'Transactions',
          url:'/admin-transactions'
        },
  ]
  public static menuArray = [
    {
      name: 'overview',
      url: 'overview',
      icon:'grid'
    },
    {
      name: 'loans',
      url: 'loans',
      icon:'briefcase'
    },
    {
      name: 'wallet',
      url: 'savings',
      icon:'wallet'
    },
    {
      name: 'payments',
      url: 'payments',
      icon:'card'
    },
    {
      name: 'HMO',
      url: 'hmo',
      icon:'medkit'
    },
    {
      name: 'settings',
      url: 'settings',
      icon:'cog'
    },
  ]
  public static lenderArray = [
    {
      name: 'overview',
      url: 'overview',
      icon:'grid'
    },
    {
      name: 'lendings',
      url: 'loans',
      icon:'briefcase'
    },
    {
      name: 'wallet',
      url: 'savings',
      icon:'wallet'
    },
    {
      name: 'settings',
      url: 'settings',
      icon:'cog'
    },
  ]
  public static uniArray = [
    {
      name: 'overview',
      url: 'admin-dashboard',
      icon:'grid'
    },
    {
      name: 'students',
      url: '/admin-students',
      icon:'people'
    },
    {
      name: 'verification requests',
      url: 'admin-vrs',
      icon:'checkbox'
    },
    {
      name: 'scholarship applications',
      url: 'admin-scholarships',
      icon:'medal'
    },
    {
      name: 'loans',
      url: 'admin-loans',
      icon:'briefcase'
    },
    {
      name: 'loan attempts',
      url: 'admin-loan-attempts',
      icon:'briefcase'
    },
    {
      name: 'agents',
      url: 'admin-agents',
      icon:'people'
    },
    {
      name: 'departments',
      url: '/admin-depts',
      icon:'home'
    },
    {
      name: 'faculties',
      url: '/admin-faculties',
      icon:'home'
    },
    {
      name: 'settings',
      url: 'settings',
      icon:'cog'
    },
  ]
  public static adminArray = [
    {
      name: 'overview',
      url: 'admin-dashboard',
      icon:'grid'
    },
    {
      name: 'students',
      url: '/admin-students',
      icon:'people'
    },
    {
      name: 'academic details',
      url: '/admin-academic-records',
      icon:'library'
    },
    {
      name: 'loans',
      url: 'admin-loans',
      icon:'briefcase'
    },
    {
      name: 'pay tuition requests',
      url: 'admin-pay-request',
      icon:'notifications'
    },
    {
      name: 'wallets',
      url: 'admin-wallets',
      icon:'wallet'
    },
    {
      name: 'wallet history',
      url: 'admin-wallet-history',
      icon:'wallet'
    },
    {
      name: 'living expense requests',
      url: '/admin-living-expense-requests',
      icon:'receipt'
    },
    {
      name: 'agents',
      url: 'admin-agents',
      icon:'people'
    },
    {
      name: 'departments',
      url: '/admin-depts',
      icon:'home'
    },
    {
      name: 'faculties',
      url: '/admin-faculties',
      icon:'home'
    },
    {
      name: 'institutions',
      url: 'admin-institutions',
      icon:'home'
    },
    {
      name: 'users',
      url: 'admin-users',
      icon:'people'
    },
    {
      name: 'groups',
      url: 'admin-groups',
      icon:'people'
    },
    {
      name: 'transactions',
      url: 'admin-transactions',
      icon:'reader'
    },
  ]
  public static agentArray = [
    {
      name: 'overview',
      url: 'admin-dashboard',
      icon:'grid'
    },
    {
      name: 'students',
      url: '/admin-students',
      icon:'people'
    },
    {
      name: 'verification requests',
      url: 'admin-vrs',
      icon:'checkbox'
    },
    {
      name: 'loans',
      url: 'admin-loans',
      icon:'briefcase'
    },
    {
      name: 'settings',
      url: 'settings',
      icon:'cog'
    },
  ]
  public static bankArray = [
    {
      name: 'overview',
      url: 'admin-dashboard',
      icon:'grid'
    },
    {
      name: 'students',
      url: '/admin-students',
      icon:'people'
    },
    {
      name: 'institutions',
      url: 'admin-institutions',
      icon:'home'
    },
    /*{
      name: 'permission',
      url: 'admin-permission',
      icon:'lock-open'
    },*/
    {
      name: 'settings',
      url: 'settings',
      icon:'cog'
    },
  ]
  public static legalArray = [
    {
      name: 'overview',
      url: 'admin-dashboard',
      icon:'grid'
    },
    {
      name: 'students',
      url: '/admin-students',
      icon:'people'
    },
    {
      name: 'loans',
      url: 'admin-loans',
      icon:'briefcase'
    },
    {
      name: 'institutions',
      url: 'admin-institutions',
      icon:'home'
    },
    /*{
      name: 'permission',
      url: 'admin-permission',
      icon:'lock-open'
    },*/
    {
      name: 'settings',
      url: 'settings',
      icon:'cog'
    },
  ]
}