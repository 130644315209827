import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ActionSheetController, Platform, ToastController, AlertController, ModalController, LoadingController, PopoverController } from '@ionic/angular';
import { Location } from '@angular/common';
import { GlobalConstants } from '../common/global-constants';
import { Title } from '@angular/platform-browser';
import { DataService } from './data-service.service';
import { Storage } from '@ionic/storage';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { BrowserTab } from '@ionic-native/browser-tab/ngx';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { BehaviorSubject, Observable } from 'rxjs';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ReloadServiceService } from './reload-service.service';
import { InHouseScholarshipsPage } from '../pages/scholarships/in-house-scholarships/in-house-scholarships.page';
import { App } from '@capacitor/app';
import { Share } from '@capacitor/share';
import OneSignal from 'onesignal-cordova-plugin';
declare var PaystackFee: any;
var payArr = [];
@Injectable({
  providedIn: 'root'
})
export class FunctionServiceService {
  private isSafe:BehaviorSubject<any>;
  private creditRemoved:BehaviorSubject<any>;
  api = GlobalConstants.baseUrl+'api/';

  constructor(
    private http:HttpClient,
    private alertCtrl:AlertController,
    private toastCtrl:ToastController,
    private platform:Platform,
    private actionSheetCtrl:ActionSheetController,
    private router:Router,
    private location:Location,private title:Title,
    private modalCtrl:ModalController,
    private ds:DataService,
    private rl:ReloadServiceService,
    private storage:Storage,
    private loadingController:LoadingController,
    public popoverController: PopoverController,
    public activatedRoute: ActivatedRoute, 
    private browserTab: BrowserTab,
    private iab: InAppBrowser,
  ) {
    this.isSafe = new BehaviorSubject<any>(false);
    this.creditRemoved = new BehaviorSubject<any>(false);
    this.locationCoords = {
      latitude: '',
      longitude: '',
      accuracy: '',
      timestamp: '',
    };
  }
  currentPopover;locationCoords;
  socialSharing = new SocialSharing();
  androidPermission = new AndroidPermissions();
  locationAccuracy = new LocationAccuracy();
  geolocation = new Geolocation();
  appVersion = new AppVersion()
  options : InAppBrowserOptions = {
      location : 'yes',//Or 'no' 
      hidden : 'no', //Or  'yes'
      clearcache : 'yes',
      clearsessioncache : 'yes',
      zoom : 'yes',//Android only ,shows browser zoom controls 
      hardwareback : 'yes',
      mediaPlaybackRequiresUserAction : 'no',
      shouldPauseOnSuspend : 'no', //Android only 
      closebuttoncaption : 'Close', //iOS only
      disallowoverscroll : 'no', //iOS only 
      toolbar : 'yes', //iOS only 
      enableViewportScale : 'no', //iOS only 
      allowInlineMediaPlayback : 'no',//iOS only 
      presentationstyle : 'pagesheet',//iOS only 
      fullscreen : 'yes',//Windows only    
  };
  public checkLogin(){
    /*
    this.ds.getValue().subscribe((data)=>{
      setTimeout(()=>{
        //if(!data)
          //this.loadPath('login');
      },5000)
    });*/
    this.ds.verifyLogin();
  }
  public getAppVersion(){
    if(this.platform.is('capacitor')){
      App.getInfo().then((data)=>{
        GlobalConstants.appVersion = data?.version;
      })
    }    
  }
  public setSlug(input){
    if(input){
        var data = input.replace(/\s+/g,'-');
        return data.toLowerCase();
    }
  }
  public filterArr(object,val,key?){
    if(!object)
    return;
    return object.filter(
      it=>{
        return it[key].includes(val);
      }
    )
  }
  public findIndex(value,array,key){
    var i;
    if (value !== undefined) {
      i = array.findIndex(page => page[key] === value);
      return i;//array[i];
    }
  }
  public getIndex(value,array,key){
    value = this.getUrlPath3();
    var i;
    if (value !== undefined) {
      i = array.findIndex(page => page[key].toLowerCase() === value.toLowerCase());
      if(array[i] && array[i].title){
        this.title.setTitle(array[i].title+' - '+GlobalConstants.appName);
      }
      return array[i];
    }
  }
  public setTitle(name){
    this.title.setTitle(name);
  }
  public getUrlPath(){
    //return this.router.url;
    return window.location.href;
  }
  public getUrlPath3(){
    return this.router.url;
  }
  public getUrlPath2(){
    return window.location.href;
  }
  public getPathID(){
    return this.activatedRoute.snapshot.paramMap.get('id');
  }
  public loadPath(path){
    if(path=='home'){
      if(GlobalConstants.history && GlobalConstants.history.length>1){
        this.goBack();
        return;
      }
    }
    this.router.navigate([path]);
  }
  public loadReplace(path){
    this.router.navigate([path],{replaceUrl:true});
  }
  public navigateURL(id,state?){
    this.router.navigateByUrl(id,{state:state})
  }
  public goBack(){
    this.location.back();
  }
  async loadAlert(txt,header?){
    const alert = await this.alertCtrl.create({
      header: header?header:'Attention!',
      message: txt,
      buttons: [
        {
          text: 'Ok',
          role: 'okay',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }
  doLogout(type?){
    this.storage.remove(GlobalConstants.tokenName).then(()=>{
      this.presentToast('You have been logged out.');
      this.ds.setValue(false);
      this.ds.setProfile([]);
      GlobalConstants.token = '';
      if(this.getUrlPath3().includes('welcome'))
        return;
      this.loadPath('/sign-in');
      localStorage.removeItem('redir')
    })
  }
  async loadConfirm(txt,header?,type?){
    const alert = await this.alertCtrl.create({
      header: header?header:'Attention!',
      message: txt,
      buttons: [
        {
          text: 'Yes, log me out!',
          role: 'okay',
          cssClass: 'secondary',
          handler: () => {
            this.storage.remove(GlobalConstants.tokenName).then(()=>{
              localStorage.removeItem('redir')
              this.presentToast('You have been logged out.');
              this.ds.setValue(false);
              this.ds.setProfile([]);
              GlobalConstants.token = '';
              if(this.getUrlPath3().includes('welcome'))
                return;
              this.loadPath('/sign-in');
            })
          }
        },
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }
  async presentToast(txt,duration?,color?,position?){
    const toast = await this.toastCtrl.create({
      message:txt,
      duration: duration?duration:4000,
      color: color?color:'',
      position:position?position:'bottom'
    });
    toast.present();
  }
  async presentToastWithOptions(txt,btnText,duration?,color?,term?) {
    const toastOption = await this.toastCtrl.create({
      header: '',
      message: txt,
      position: 'bottom',
      color: color?color:'danger',
      duration: 5000,
      buttons: [
        {
          text: ' '+btnText,
          handler: () => {
            this.ds.setReload(term,true);
          }
        },
      ]
    });
    toastOption.present();
  }
  async share(txt,link){
    if(this.platform.is('capacitor')){
      /*this.socialSharing.share(txt,null,null,link).then(() => {
        console.log('shared');
      }).catch(() => {
        console.log('could not share');
      });*/
      await Share.share({
        title: 'Share',
        text: txt,
        url: link,
        dialogTitle: 'Share',
      });
    }
    else{
      this.presentActionSheet(txt,link);
    }
  }
  async presentActionSheet(txt,link){
    const actionSheet = await this.actionSheetCtrl.create({
      header:'Share on',
      buttons:[
        {
          text:'Facebook',
          role:'destructive',
          icon: 'logo-facebook',
          handler:()=>{
            window.open('https://www.facebook.com/sharer/sharer.php?u='+link+'&quote='+txt)
          }
        },
        {
          text:'Twitter',
          role:'destructive',
          icon: 'logo-twitter',
          handler:()=>{
            window.open(`http://twitter.com/share?url=${link}&text=${txt}&via=${'presspayng'}`)
          }
        },
        {
          text:'Whatsapp',
          role:'destructive',
          icon: 'logo-whatsapp',
          handler:()=>{
            window.open(`https://api.whatsapp.com/send?text=${txt} at ${link}`)
          }
        }
      ]
    });
    await actionSheet.present();
  }
  win;
  openUrl(url,target?,type?,trans?){
    if(!url){
      this.presentToast('URL not provided')
      return;
    }
    if(type=='inapp'){
      //console.log(url)
      if(!this.platform.is('capacitor')){
        this.win = window.open(url,'targetWindow','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=550,height=450')
        //this.win = window.open('https://stackoverflow.com/questions/10792408/open-popup-and-refresh-parent-page-on-close-popup', 'windowName',"width=200,height=200,scrollbars=no");   
        var timer = setInterval(()=>{   
            if(this.win && this.win.closed) {  
              clearInterval(timer);
              this.ds.setReload(trans,true)  
            }  
        }, 1000); 
      }
      else{
        var browser = this.iab.create(url,target,this.options);
        browser.on('loadstop').subscribe(event => {
          browser.insertCSS({ code: "body{color: red;" });
        });
        browser.on('exit').subscribe(event => {
          //reload transaction
          this.ds.setReload(trans,true)  
        });
        //browser.close();
      }
    }
    else{
      if(target && target!='_self'){
        target = '_system';
      }
      var fb = url.split('facebook');
      var tw = url.split('twitter');
      var inst = url.split('instagram');
      var covid = url.split('covid');
      var name;
      if(fb.length>1){
        name = 'facebook'
      }
      else if(tw.length>1){
        name = 'twitter'
      }
      else if(inst.length>1){
        name = 'instagram'
      }
      else if(covid.length>1){
        name = 'covid'
      }
      if(this.platform.is('capacitor')){
        this.browserTab.isAvailable()
          .then((isAvailable: boolean) => {
  
          if(isAvailable) {
            this.browserTab.openUrl(url);
  
          } else {
  
              // if custom tabs are not available you may  use InAppBrowser
              if(fb.length>1 || tw.length>1 || inst.length>1 || covid.length>1){
                target = '_system';
                window.open(url,'_system');
                return;
              }
              //openWithSystemBrowser = _system;
              //openWithInAppBrowser = _blank;
              //openWithCordovaBrowser = _self;
              this.iab.create(url,target,this.options);
          }
  
          });
      }
      else{
        // if custom tabs are not available you may  use InAppBrowser
        if(fb.length>1 || tw.length>1 || inst.length>1 || covid.length>1){
          target = '_system';
          window.open(url,'_system');
          return;
        }
        this.iab.create(url,target,this.options);
      }
    }
    /*this.browser = this.iab.create(url);
    this.browser.on('loadstop').subscribe(event => {
      this.browser.insertCSS({ code: "body{color: red;" });
   });
   this.browser.close();*/
  }
  async openModal(data,name){
    const maintModal = await this.modalCtrl.create(data);
    maintModal.onDidDismiss().then((data)=>{
      if(data.data){
        this.ds.setReload(name,data.data);
      }
    })
    await maintModal.present();
  }
  closeModal(data?){
    this.modalCtrl.getTop().then(v => v ? this.modalCtrl.dismiss(data) : null);
  }
  confirm(term,item,action,type){
    if(GlobalConstants.isLoading)
      return;
    this.loadConfirm('Do you want to proceed with '+action+'','Confirm Action',term);
    this.ds.getReload().subscribe((data)=>{
    })
  }
  async prepConfirm(term,item,action,type?){
    const alert = await this.alertCtrl.create({
      header: 'Attention!',
      message: 'Do you want to proceed with '+action,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          text: 'Ok',
          role: 'okay',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }
  async prepConfirm2(term,item,action,txt?){
    var text = txt;
    if(!txt){
      text = 'Do you want to proceed with '+action;
    }
    const alert = await this.alertCtrl.create({
      header: 'Attention!',
      message: text,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          text: 'Ok',
          role: 'okay',
          cssClass: 'secondary',
          handler: () => {
            var obj;
            obj = true;
            if(item){
              obj = item;
            }
            this.ds.setReload(term,obj)
          }
        }
      ]
    });
    await alert.present();
  }
  async prepDelete(term,id,notProfile?){
    const alert = await this.alertCtrl.create({
      header: 'Attention!',
      message: 'Do you really want to delete?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          text: 'Yes',
          role: 'okay',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }
  reloadProfile(){
    this.ds.getProfileData();
  }
  logout(type?){
    this.loadConfirm('Are you sure you want to log out?','Confirm Action','login');
  }
  dateDiff(date){
    if(!date)
    return '';
    var then;var now;var diff;var res;
    now = new Date();
    then = new Date(date);
    var val = (now - then)/(3600*24*1000);
    console.log(val);
    return val;
  }
  presentLoading(duration?,text?) {
    this.platform.ready().then(()=>{
      this.loadingController.getTop().then(v => v ? null : this.startLoader(duration,text));
    })
  }
  async startLoader(duration?,text?){
    if(GlobalConstants.isLoader)
    return;
    var txt = 'Please wait...';
    GlobalConstants.isLoader = true;
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: (text)?txt+text:txt,
      duration: (duration)?duration:''
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
  }
  async presentLoadingWithOptions(duration?) {
    if(GlobalConstants.isLoader)
      return;
    GlobalConstants.isLoader = true;
    var duration2 = (duration)?duration:5000;
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      spinner: null,
      duration: duration2,
      message: 'Click the backdrop to dismiss early...',
      translucent: true,
      backdropDismiss: false
    });
    setTimeout(()=>{
      GlobalConstants.isLoader = false;
    },duration2)
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
  }
  dismissLoader(){
    setTimeout(()=>{
    },1000)
    this.loadingController.getTop().then(v => v ? this.doStopLoader() : null);
  }
  doStopLoader(){
    GlobalConstants.isLoader = false;
    this.loadingController.dismiss();
  }
  log(data){
    if(GlobalConstants.isTesting)
      console.log(data);
  }
  filterUnits(object,id){
    console.log(object);
    console.log(id);
    if(!id)
    return;
    var val = object.filter(it=>{return it['id'].includes(id)},true);
    console.log(val);
    var val2 = object.filter(it=>{return it['base_unit'].includes(id)},true)[0];
    val = val.push(val2);
    var defaultUnit = val[0].id;
    return val;
  }
  async presentPopover(ev: any,cp,cps?) {
    const popover = await this.popoverController.create({
      component: cp,
      componentProps: cps,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true
    });
    this.currentPopover = popover;
    return await popover.present();
  }
  dismissPopover() {
    if (this.currentPopover) {
      this.currentPopover.dismiss().then(() => { this.currentPopover = null; });
    }
  }
  public sendWhatsapp(item,type?){
    this.log(item)
    var customer = item.customer;
    var biller = item.biller;
    var reference_no = item.reference_no;
    if(item && item.customer && item.customer.id){
        customer = item.customer.name;
    }
    if(item && item.biller && item.biller.id){
        biller = item.biller.company;
    }
    if(item && item.inv && item.inv.reference_no){
        reference_no = item.inv.reference_no;
    }
    var sale_id;
    if(item && item.inv && item.inv.id){
        sale_id = item.key;
    }
    else{
        sale_id = item.key;
    }
    var txt;
    if(type=='quote')
      txt = "Hello "+customer+", %0a%0a Please find the attachment for our purposed quotation ("+reference_no+") details. You can view invoice online and make payment (if payment is yet to be made) at "+GlobalConstants.baseUrl+"server/quote/"+sale_id+" %0a%0aBest regards,%0a%0a"+biller;
    else
      txt = "Hello "+customer+", %0a%0a Please find the attachment for your order ("+reference_no+") details. You can view invoice online and make payment (if payment is yet to be made) at "+GlobalConstants.baseUrl+"server/invoice/"+sale_id+" %0a%0aBest regards,%0a%0a"+biller;
    window.open('https://api.whatsapp.com/send?text='+txt,'_blank');
  }
  download(term,id,name){
    this.presentToast('Download will begin shortly');
    if(term=='sale')
      this.ds.downloadFile(`sales/pdf/${id}/0/0`,name);
    else if(term=='quote')
      this.ds.downloadFile(`quotes/pdf/${id}/0/0`,name);
  }
  action(term){
    if(term=='website')
      this.openUrl(GlobalConstants.baseUrl,'_blank')
    else{
      this.loadPath(term)
    }
  }
  doAction(type,url,name?,id?,data?){
    var url
    if(type=='export'){
      if(!data || (data && data.length<1)){
        this.presentToast('No record to be exported');
        return;
      }
      var fm = {};
      fm['form_action'] = 'export_excel';
      fm['val'] = data;
      this.downloadExcel(url,name,fm,true)
    }
  }
  async prepConfirmSimple(term,txt){
    const alert = await this.alertCtrl.create({
      header: 'Attention!',
      message: txt,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          text: 'Ok',
          role: 'okay',
          cssClass: 'secondary',
          handler: () => {
            if(term=='resendRef'){
              this.rl.setResendRef(true);
            }
            else
            this.ds.setReload(term,true);
          }
        }
      ]
    });
    await alert.present();
  }
  checkSafe(id){
    var data;
    this.storage.get('profileHistory').then((showProfile)=>{
      if(!showProfile){
        data = false;
      }
      else{
        if(showProfile.indexOf(id)>-1){
            data = true;
        }
        else{
            data = false;
        }
      }
      this.setSafe(data)
    })
  }
  getSafe(): Observable<any> {
    return this.isSafe.asObservable();
  }
  setSafe(newValue): void {
    this.isSafe.next(newValue);
  }
  checkGPSPermission(){
    this.androidPermission.checkPermission(this.androidPermission.PERMISSION.ACCESS_COARSE_LOCATION).then(
      result =>{
        if(result.hasPermission){
          this.askToTurnOnGPS();
        }
        else{
          this.requestGPSPermission();
        }
      },err=>{
        alert(err);
      }
    );
  }
  requestGPSPermission(){
    this.locationAccuracy.canRequest().then((canRequest: boolean)=>{
      if(canRequest){
        console.log("4");
      }
      else{
        //show GPS permission request dialogue
        this.androidPermission.requestPermission(this.androidPermission.PERMISSION.ACCESS_COARSE_LOCATION)
        .then(
          ()=>{
            //call method to turn GPS on
            this.askToTurnOnGPS();
          },error=>{
            //show alert if user says no
            this.loadAlert('requestPermission Error requesting location permission '+error);
          }
        )
      }
    });
  } 
  askToTurnOnGPS(){
    this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
      ()=>{
        //When GPS turned on call method to get Accurate location coords
        this.getLocationCoords();
      },error => {
        this.loadAlert('Error requesting location permission '+ JSON.stringify(error));
      }
    )
  }
  getLocationCoords(){
    this.geolocation.getCurrentPosition().then(
      (resp)=>{
        GlobalConstants.lat = this.locationCoords.latitude = resp.coords.latitude;
        GlobalConstants.lon = this.locationCoords.longitude = resp.coords.longitude;
        this.locationCoords.accuracy = resp.coords.accuracy;
        this.locationCoords.timestamp = resp.timestamp;
      }
    ).catch((error) => {
      console.log('Error getting location', error);
      if(error.code===1){
        this.presentToast('You denied us access to your geolocation. You cannot create or edit profile your profile since we do not know if you are in or outside Oyo state',5000);
      }
      if(error.code===2 && !GlobalConstants.isCordova){
        this.presentToast('Your network is off and we cannot use your geolcation feature. Please try again when you are connected to the internet!',5000);
      }
      else if(!GlobalConstants.isCordova){
        this.presentToast('Your network is off and we cannot use your geolcation feature. Please try again when you are connected to the internet!',5000);
      }
      else
        this.presentToast('You denied us access to your geolocation. You cannot create or edit profile your profile since we do not know if you are in or outside Oyo state.',5000);
    });
    setTimeout(()=>{
      //console.log(GlobalConstants.lat);
      //console.log(this.locationCoords);
    },4000)
  }
  calcPay(amount) {
    var gateway,total;
    var obj = new PaystackFee();
    if(amount<2500){
        gateway = (1.5/100)*amount; 
        total =  gateway + amount;
    }
    else{
        gateway = ((1.5/100)*amount) + 100; 
        if(gateway>2000){
            gateway = 2000;
        }
        total = gateway + amount;
    }
    total = obj.addFor(amount*100);
    gateway = (total/100) - amount;
    
    payArr['totalKobo'] = total;
    payArr['gateway'] = gateway;
    payArr['total'] = total/100;
    payArr['actualAmount'] = amount;
    return payArr;
  }
  actionBtn(type,url,name?,id?,data?){
    var url
    if(type=='export'){
      if(!data || (data && data.length<1)){
        this.presentToast('No record to be exported');
        return;
      }
      var fm = {};
      fm['form_action'] = 'export_excel';
      fm['val'] = data;
      this.downloadExcel(url,name,fm,true)
    }
    else{
      if(!data || (data && data.length<1)){
        this.presentToast('No record to be exported');
        return;
      }
      var fm = {};
      fm['form_action'] = type;
      fm['val'] = data;
      this.downloadExcel(url,name,fm,true)
    }
  }
  downloadExcel2(url,name,data,type?){
    this.presentToast('Download will begin shortly');
    this.ds.downloadFilePost(`${url}`,name,data,type)
  }
  downloadFilePost2(route: string, filename: string = null,body?,type?): void{
    const headers = new HttpHeaders();
    headers.set('Animal', 'Bearer '+GlobalConstants.token);
    headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    this.http.post(this.api + route,body,{headers, responseType: 'blob' as 'json'}).subscribe(
        (response: any) =>{
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            if(type)
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
            else
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (filename)
              downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    )
  }
  downloadExcel(url,name,data,type?){
    this.presentToast('Download will begin shortly');
    this.ds.downloadFilePost(`${url}`,name,data,type)
  }
  downloadFilePost(route: string, filename: string = null,body?,type?): void{
    const headers = new HttpHeaders();
    headers.set('Animal', 'Bearer '+GlobalConstants.token);
    headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    this.http.post(this.api + route,body,{headers, responseType: 'blob' as 'json'}).subscribe(
        (response: any) =>{
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            if(type)
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
            else
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (filename)
              downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    )
  }
  defaultHref(path){
    if(path){
      if(GlobalConstants.history && GlobalConstants.history.length>1){
        this.goBack();
        return;
      }
      this.router.navigate([path]);
    }
  }
  requestVerification(){
    this.ds.requestVerification({}).subscribe((data:any)=>{
      if(data.status){
        this.loadAlert(data.message,'All done!')
      }
      else{
        this.loadAlert(data.message,'Sorry!')
      }
    },error=>{
      var msg = error.message.split('http');
      msg = msg[0];
      if(msg.length>1){
        msg = msg + ' action. Ensure you have a working internet and try again!';
      }
      else
        msg = msg + ' Please try again!';
      this.loadAlert(msg,error.statusText);
    })
  }
  loadError(error){
    if(!error.message)
      return;
    var msg = error.message.split('http');
    msg = msg[0];
    if(msg.length>1 && error.status<400){
      msg = msg + ' action. Ensure you have a working internet and try again!';
    }
    else if(msg.length>1 && (error.status==401)){
      error.statusText = 'Sorry, something went wrong';
      msg = (error?.error?.message)?error.error.message:msg;
      this.loadReplace('login')
      return;
    }
    else if(msg.length>1 && (error.status==403 || error.status==400)){
      error.statusText = 'Sorry, something went wrong';
      msg = (error?.error?.message)?error.error.message:msg;
    }
    else if(msg.length>1 && error.status>=400 && error.status<500){
      msg = msg + ' action. You are not authorized to perform action!';
    }
    else if(msg.length>1 && error.status>=500){
      msg = msg + ' action. Please contact admin to resolve issue!';
    }
    else
      msg = msg + ' Please try again!';
    this.loadAlert(msg,error.statusText);
  }
  isEmpty(obj) {
    for(var key in obj) {
      //obj.hasOwnProperty
      //obj.hasOwnProperty(key)
      if((obj[key])){
        return false;
      }
    }
    return true;
  }
  time
  getTime(){
    var myDate = new Date();
    var hrs = myDate.getHours();
    if(hrs<12){
      this.time = ' morning';
    }
    else if(hrs>=12 && hrs<=17){
      this.time = ' afternoon';
    }
    else if(hrs>=17 && hrs<=24){
      this.time = ' evening';
    }
    GlobalConstants.time = 'Good'+this.time;
  }
  generateReference(): string {
    let date = new Date();
    return date.getTime().toString();
  }
  sessions:any = [];
  loadSessions(){
    this.sessions = [];
    var year = new Date().getFullYear();
    var nextyear = year+1;
    var lastyear = year-1;
    var prevyear = year-2;
    this.sessions.push({'name':(prevyear-1)+'/'+(lastyear-1)})
    this.sessions.push({'name':prevyear+'/'+lastyear})
    this.sessions.push({'name':lastyear+'/'+year})
    this.sessions.push({'name':year+'/'+nextyear})
    this.sessions.push({'name':nextyear+'/'+(nextyear+1)})
    return this.sessions;
  }
  htmlDate(dateObject){
    var date = new Date(dateObject);
    var month:any = ((date.getMonth())+1);
    if(month<10)
      month = ('0'+((date.getMonth())+1));
    var day:any = date.getDate();
    if(day<10)
      day = '0'+date.getDate();
    return date.getFullYear()+'-'+month+'-'+day;
  }
  resource:any;
  scholarship(){
    var isOpened = 0;
    this.ds.getCandidateValue().subscribe((data:any)=>{
      if(data && (data?.is_verified!='1' && !data?.istopped)){
        this.loadAlert('You need an FCMB account to proceed. '+GlobalConstants.txtMinBal);this.loadPath('top-up');return;
      }
      else if(data && (data?.is_verified=='1' || data?.istopped)){
        isOpened++;
        if(isOpened>2)
        return;
        this.openModal({
          component:InHouseScholarshipsPage,
          backdropDismiss:false
        },'')
      }
      else if(data && data?.is_verified=='0'){
        this.loadAlert('Your account has not been verified. You will now be redirected to the verification page.')
      }
      else if(data && data?.is_verified=='2'){
        this.loadAlert('Your account verification is under review kindly exercise patience.')
      }
      else{
        this.loadAlert('Your verification status could not be determined. Please contact admin at support@presspay.ng')
      }
    })
  }
  getPlayerID(){
    //this.loadAlert('Load player iD 1')
    if(!this.platform.is('capacitor')){
      if(!GlobalConstants.playerID && GlobalConstants.isTesting)
        GlobalConstants.playerID = '13ddd391-68e0-4abc-85a5-6f781a3c56ee'; 
    }
    else{
      this.getPushStatus();
    }
  }
  getPushStatus(){
    if(!this.platform.is('capacitor')){
      if(GlobalConstants.isTesting)
        return true;
    }
    else{
      //this.loadAlert('Load player iD 2')
      OneSignal.addSubscriptionObserver(function (state:any) {
        if (!state.from.subscribed && state.to.subscribed) {
          this.loadAlert("Subscribed for OneSignal push notifications!")
          // get player ID
          GlobalConstants.playerID = state.to.userId;
        }
        GlobalConstants.hasPush = state.to.subscribed
        console.log("Push Subscription state changed: " + JSON.stringify(state));
        if(state && state.to)
          GlobalConstants.playerID = state.to.userId;
        console.log("Player ID: " + GlobalConstants.playerID);
      });
      /*this.oneSignal.getPermissionSubscriptionState().then((status)=>{
        GlobalConstants.playerID = status.subscriptionStatus.userId;
        GlobalConstants.hasPush = status.subscriptionStatus.subscribed;
        //this.fnService.loadAlert(JSON.stringify(status.subscriptionStatus));
      },error=>{
        this.presentToast('An error occured while fetching push');
      }).catch((reason: any) => {
        this.presentToast(JSON.stringify(reason));
      });*/
    }
  }
  subscribe(value:boolean){
    //this.presentToast('Now trying to subscribe')
    if(GlobalConstants.isCordova){
      //this.oneSignal.registerForPushNotifications();
      //this.oneSignal.setSubscription(value);
      //this.presentToast('Now subscribed')
      //this.fnService.loadAlert('subscription has been done: '+value);
      if(value){
        GlobalConstants.hasPush = true;
      }
      else{
        GlobalConstants.hasPush = false;
      }
        //window.plugins.OneSignal.registerForPushNotifications();
      // Only works if user previously subscribed and you used setSubscription(false) below
      //window.plugins.OneSignal.setSubscription(true);
    }
  }
  dismissLoaderReal(){
    setTimeout(()=>{
      this.dismissLoader();
    },2000)
  }
  calc(Price,Fee?){
    if(!Fee)
    Fee = 1.4;
    Price = parseFloat(Price)
    Fee = Fee/100;
    var final = (Price / (1 - Fee)) + 0.01
    return final;
  }
  calc2(Final,Fee?){
    if(!Fee)
    Fee = 1.4;
    var Price;
    Final = parseFloat(Final)
    Fee = Fee/100;
    Price = (Final - 0.01) * (1 - Fee);
    return Price + 1.4;
  }
  addDays(date,days){
    var result = new Date(date);
    result.setDate(result.getDate() + days)
    return result;
  }
}
